import React, { useState } from "react";
import { DateRangePicker as ReactDateRangePicker } from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { DateRange } from "common/models";

type DateRangePickerProps = {
  format?: string;
  minDate?: Date;
  maxDate?: Date;
  value: DateRange;
  className?: string;
  onChange: (dateRange: DateRange) => void;
};

const DateRangePicker = ({
  format,
  maxDate,
  value,
  className,
  minDate,
  onChange,
}: DateRangePickerProps) => {
  const handleChange = (date) => {
    onChange(date as DateRange);
  };
  return (
    <>
      <ReactDateRangePicker
        calendarAriaLabel="Toggle calendar"
        clearAriaLabel="Clear value"
        dayAriaLabel="Day"
        monthAriaLabel="Month"
        yearAriaLabel="Year"
        nativeInputAriaLabel="Date"
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        onChange={handleChange}
        value={value}
        maxDate={maxDate}
        format={format}
        className={className}
        minDate={minDate}
      />
    </>
  );
};
export default DateRangePicker;

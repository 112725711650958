import { ChatHistoryBookmarkResponse } from "common/models/chat/chat-bookmark.model";
import { ChatHistoryDeleteResponse, ChatHistoryDetail, ChatHistoryDetailsResponse, ChatHistoryModel,UserChatHistoryData } from "common/models/chat/chat-history.model";

interface IChatHistoryState{
  chatHistory:ChatHistoryModel|undefined;
  searchTriggered:boolean;
  searchValue:string;
  searchResults:ChatHistoryModel|undefined;
  chatHistoryData: UserChatHistoryData|undefined;
  selectedConversation:ChatHistoryDetailsResponse|undefined;
  chatHistoryConversationDeleted:ChatHistoryDeleteResponse|undefined;
  chatBookmark:ChatHistoryBookmarkResponse|undefined;
  removeBookmark:ChatHistoryBookmarkResponse|undefined;
}

const initialChatHistoryState: IChatHistoryState = {
    chatHistory:undefined,
    searchTriggered:false,
    searchValue:"",
    searchResults:undefined,
    chatHistoryData:undefined,
    selectedConversation:undefined,
    chatHistoryConversationDeleted:undefined,
    chatBookmark:undefined,
    removeBookmark:undefined
};


export { initialChatHistoryState };

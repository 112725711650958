import { ApiStatus } from "common/enums";
import { SearchMessages, UserChatHistory } from "common/models/chat/chat-history.model";
import { IDocumentDetails, IDocumentPage, IPdfDocsData } from "common/models/chat/reference-document.model";

interface IChatState{
    userFeedbackState:any;
    pdfDocumentDetails:IDocumentDetails|undefined;
    handsOffAcknowledged:Array<{id:string,message:string}>;
    viewDocURL:string;
    viewDocGuid:string;
    viewDocName:string;
    viewDocPageNumbers : IDocumentPage[];
    isDocumentOpen : boolean;
    chatScrollHeight:any;
    agentLeavingAcknowledged:Array<{id:string,message:string}>;
    messageInputEnabled : boolean;
    messagesDisplayed : Array<string>;
    chatSearchResults : UserChatHistory[];
    searchMessages : SearchMessages; 
    chatScrolled:boolean;
    showChatHistoryPanel:boolean;
    stopTyping:boolean;
    activitiesCopy:Array<any>;
    chatSelected:string|undefined;
    documentsData: IPdfDocsData;
    botConnectingStatus: ApiStatus;
    pdfFileState:any;
}

const initalChatState: IChatState = {
    userFeedbackState:undefined,
    pdfDocumentDetails:undefined,
    handsOffAcknowledged:[],
    viewDocURL:"",
    viewDocGuid:"",
    viewDocName:"",
    viewDocPageNumbers:[],
    isDocumentOpen:false,
    chatScrollHeight:undefined,
    agentLeavingAcknowledged:[],
    messageInputEnabled:false,
    messagesDisplayed:[],
    chatSearchResults:[],
    searchMessages:{
        status:ApiStatus.NONE
    },
    chatScrolled:false,
    showChatHistoryPanel:false,
    stopTyping:false,
    activitiesCopy:[],
    chatSelected:undefined,
    documentsData: {
        documents: [],
        activityId: ''
    },
    botConnectingStatus: ApiStatus.LOADING,
    pdfFileState:undefined
};

export { initalChatState };

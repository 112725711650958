import { createDirectLine, createStore } from "botframework-webchat";
import { Components } from "botframework-webchat-component";
import React, { memo, useEffect, useState } from "react";
import Chat from "../Chat/Chat";
import ChatHistoryPanel from "../ChatHistoryPanel/ChatHistoryPanel";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./Chatbot.scss";
import { IDocumentPage } from "common/models/chat/reference-document.model";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";
import { chatActions } from "modules/gpo/redux/chat";
import Feedback from "../Feedback/Feedback";
import Messages from "../Messages/Messages";
import PDFViewer from "../PDFViewer/PDFViewer";
import useSessionTimeOut from "common/hooks/useSessionTimeOut";

const ChatBot = () => {
  const [loader, setLoader] = useState(true);
  const [token, setToken] = useState("");
  
  const userState = useAppSelector((state: RootState) => state.user);
  const userStateDispatch = useAppDispatch(); 
  const chatStateDispatch = useAppDispatch(); 
  useSessionTimeOut();
  
  useEffect(() => {
    getToken();
  }, []);

  useEffect(()=>{
    if(userState?.botToken){
      setToken(userState?.botToken);  
    }
  },[userState?.botToken])

  const store = createStore(
    {},
    ({ dispatch }: any) =>
      (next: any) =>
      (action: any) => {
        if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
          setLoader(false);
        }
        return next(action);
      }
  );

  const getToken = async () => {
    userStateDispatch(userActions?.getBotConversationToken());   
  };

  const handleViewDoc = (pageNums: IDocumentPage[], documentGuid:string,activityId:string,documentName:string) => {
    chatStateDispatch(chatActions?.updateChatScrollHeight(activityId));
    chatStateDispatch(chatActions?.updateChatDocGuidState(documentGuid));
    chatStateDispatch(chatActions?.updateChatDocPageNumState(pageNums));
    chatStateDispatch(chatActions?.updateDocOpenState(true));
    chatStateDispatch(chatActions?.updateChatDocNameState(documentName));
  };

  return (
    <React.Fragment>
        <Components.Composer
          directLine={createDirectLine({ token })}
          userID="r_1675964726"
          store={store}
        >
          <div className="gpo-custom-chat-container">
            <Messages/>
            <ChatHistoryPanel/>
            <div className="gpo-custom-chat-container__chat-wrapper">
            <div id="gpo-custom-chat-wrapper" >
                <Chat onViewDoc={handleViewDoc} />            
                <PDFViewer onViewDoc={handleViewDoc}/>                
              </div>
            </div>
            <Feedback/>
          </div>
        </Components.Composer>
      {/* )} */}
    </React.Fragment>
  );
};

export default memo(ChatBot);

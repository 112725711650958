import { APP_CONTROLLER, METHOD } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";
import { ChatHistoryBookmarkRequest } from "common/models/chat/chat-bookmark.model";
import { ChatHistoryDetail, UserChatHistoryDataRequest } from "common/models/chat/chat-history.model";

const chatHistoryApiConfig = {
    getChatHistory: (searchText:string): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.CHAT}/${METHOD.HISTORY}`, 'GET',`&searchText=${searchText?searchText:""}`),
    
    getChatHistoryData: (data?:UserChatHistoryDataRequest): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.CHAT}/${METHOD.CLOSED_CONVERSATIONS}`, 'GET',data?.fromDate && data?.toDate?`fromDate=${data?.fromDate}&toDate=${data?.toDate}` : data?.fromDate ? `?fromDate=${data?.fromDate}` : ""  ),
   
    getChatHistoryDetails: (conversationGuid?:string): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.CHAT}/${conversationGuid}/${METHOD.MESSAGE}`, 'GET'),

    deleteChatHistoryDetail: (conversationGuids?:Array<string>): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.CHAT}`, 'DELETE','',conversationGuids),

    bookmarkChat: (bookmarkRequest?:ChatHistoryBookmarkRequest): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.BOOKMARKS}`, 'POST','',bookmarkRequest),
   
    removeBookmarkChat: (conversationGuid?:string): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.BOOKMARKS}/${conversationGuid}`, 'DELETE'),
};

export default chatHistoryApiConfig;

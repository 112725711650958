import { ApiResult } from "common/models";
import { put, takeLatest } from "redux-saga/effects";
import { ChatBotService } from "./chat.service";
import { chatActions } from "./chat.slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { SearchChatRequest } from "common/models/chat/chat-history.model";

const sendUserFeedback = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.sendUserFeedback(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatActions.sendUserFeedbackFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatActions.sendUserFeedbackSuccess.type,
            payload: value
        });
    }
};

const sendUserFeedbackWatch = function* () {
    yield takeLatest(chatActions.sendUserFeedback.type, sendUserFeedback);
};

const getPDFDocument = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.getPDFDocument(action?.payload);
    if (hasErrors) {
        yield put({
            type: chatActions.getPDFDetailsFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type:  chatActions.getPDFDetailsSuccess.type,
            payload: value
        });
    }
};


const getPDFDocumentWatch = function* () {
    yield takeLatest(chatActions.getPDFDetails.type, getPDFDocument);
};

const searchChatWatch = function* () {
    yield takeLatest(chatActions.searchChatBegin.type, searchChat);
};

const searchChat = function* ({payload}:PayloadAction<SearchChatRequest>) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.searchChat(payload);
    if (hasErrors) {
        yield put({
            type: chatActions.searchChatError.type,
            payload: errors
        });
    } else {
        yield put({
            type:  chatActions.searchChatSuccess.type,
            payload: value.messages ?? []
        });
    }
};
const getPDFFile = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.getPDFfile(action?.payload);
    
        if (hasErrors) {
            yield put({
                type: chatActions.getPDFFileError.type,
                payload: errors
            });
        } else {
            yield put({
                type: chatActions.getPDFFileSuccess.type,
                payload: value
            });
        }
    };
    
const getPDFFileWatch = function* () {
    yield takeLatest(chatActions.getPDFFile.type, getPDFFile);
};



export {
    sendUserFeedbackWatch,getPDFDocumentWatch, searchChatWatch, getPDFFileWatch
};

import { FC, useEffect, useState,useRef } from "react";
import { profile_logout,chat_history_button } from "assets/images";
import { useMsal, useAccount } from '@azure/msal-react';
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";
import Search from "../Search/Search";
import { chatActions } from "modules/gpo/redux/chat";

import { defaultUserIcon } from "assets/media";

import "./Header.scss";
import { chatHistoryActions } from "modules/gpo/redux/chatHistory";

type HeaderProps = {
  showProfileImage: boolean;
  showSearch:boolean;
  showHistoryButton: boolean;
};

const Header: FC<HeaderProps> = ({
  showProfileImage,
  showSearch,
   showHistoryButton
}: HeaderProps) => {

  
  const [profileImage, setProfileImage] = useState(defaultUserIcon);
  const [userDetails, setUserDetails] = useState<any>({});
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const historyButtonRef:any = useRef();

  const userState = useAppSelector((state: RootState) => state.user);
  const chatState = useAppSelector((state: RootState) => state.chat);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(userActions.getCurrentUserAvatar())
  }, []);

  useEffect(() => {
    if (userState?.avatar)
      setProfileImage(userState?.avatar);
  }, [userState?.avatar]);

  useEffect(() => {
    document.body.addEventListener('click', hideProfileMenu);
  }, []);

  useEffect(() => {
    if (userState?.userDetails) {
      setUserDetails(userState?.userDetails);
    }
  }, [userState?.userDetails])

  useEffect(() => {
    if (userState?.logoutSuccess?.isSuccess) {
      sessionStorage.clear();
      localStorage.clear();
      if(userState.isSessionTimedOut) {
        navigate("/session-timeout");
      } else {
        navigate("/logout");
      }
    }
  }, [userState?.logoutSuccess])

  const hideProfileMenu = () => {
    setShowProfile(false);
  }
  const logoutFromApplication = async () => {
    dispatch(userActions?.signOutUser());
  }
  const scrollToBottom = () =>{
    const messagesWrapper = document.getElementsByClassName('gpo-custom-chat-messages')[0];
    if(messagesWrapper){
      messagesWrapper.scroll({ top: messagesWrapper.scrollHeight,behavior:'smooth'});
    }
  }
  const showChatHistoryPanel = () =>{
    const panelVisible = chatState.showChatHistoryPanel;
    const wrapper = document.getElementsByClassName("gpo-custom-chat-container")[0];
    if(wrapper){
        wrapper?.classList?.remove("chat-history-list");
    }
    scrollToBottom();
    const historyButton:any = historyButtonRef?.current;
    if(historyButton){
        if(!panelVisible){
          historyButton?.classList?.add("showHistory");
        }
        else{
          historyButton?.classList?.remove("showHistory");
          dispatch(chatHistoryActions?.clearSelectedConversation());
        }
    }
    dispatch(chatActions?.updateShowChatHistoryPanel(!panelVisible));
    
    if(!panelVisible)
      dispatch(chatActions?.resetSearchChat());

  }
  return (
      <div className="gpo-chat-header">
        {showHistoryButton && <div ref={historyButtonRef} className="gpo-chat-header__chatHistory">
            <img src={chat_history_button} title="Show Chat History" onClick={(event: any) => { showChatHistoryPanel() }}/>
        </div>}
        <div className="gpo-chat-header__container">
          <div className="gpo-logo">
            ChatBot by <span> GPO</span>
          </div>
          {showSearch && (
          <Search/>
          )}
          {showProfileImage && (
          <div className="gpo-profile">
            <img src={profileImage} onClick={(event: any) => { event.stopPropagation(); setShowProfile(!showProfile); }} />
            {showProfile && (
              <div className="gpo-profile__details" onClick={(event: any) => { event.stopPropagation(); }}>
                <div className="profile-name">
                  <img src={profileImage} />
                  <span className="displayName">{userDetails?.displayName}</span>
                  <span>{userDetails?.emailId}</span>
                </div>
                <div className="logout" onClick={() => logoutFromApplication()}>
                  <span>
                    <img src={profile_logout} />
                    Logout
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default Header;
import React, { FC, useCallback, useEffect, useRef, useState, createContext } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import useAuthenticate from "common/hooks/useAuthenticate";
import Welcome from "modules/gpo/pages/Welcome/Welcome";
import GPOHome from "modules/gpo/pages/GPOHome/GPOHome";
import Logout from "modules/gpo/pages/Logout/Logout";
import MsalAuthProvider from "common/components/custom/MsalAuthProvider";
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser"
import { UserService } from "common/services/user.service";
import { IUserSessionDetails } from "common/models/user/user-session-details.model";
import CookieUtil from 'common/utils/cookie.util'
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";
import Unauthorized from "modules/gpo/components/Unauthorized/Unauthorized";
import SessionTimeout from "modules/gpo/pages/SessionTimeout/SessionTimeout";


const App: FC = () => {
  const [, isAuthenticated] = useAuthenticate();
  const [loggedInUserDetails, setLoggedInUserDetails] = useState({});
  const [userSignInStatus, setUserSignInStatus] = useState("");
 
  useEffect(() => {
    setCurrentUserSession();
  }, [isAuthenticated]);

  
  const userStateDispatch = useAppDispatch();
  const userState = useAppSelector((state: RootState) => state.user);

  useEffect(()=>{
    if(userState?.currentUserSession){
      let currentUserDetails:IUserSessionDetails= userState?.currentUserSession;
      if(currentUserDetails){
        setLoggedInUserDetails(currentUserDetails);
        userStateDispatch(userActions?.userUserDetailsState(currentUserDetails));
      }
     }
  },[userState?.currentUserSession])

  const setCurrentUserSession= async () => {
    userStateDispatch(userActions?.setCurrentUserSession());
  }

  return isAuthenticated? (
    <>
      {userState?.currentUserSession && userState?.currentUserSession?.hasAccess && (
        <Routes>
        <Route path="/chat" element={<GPOHome />} />
        <Route path="/logout" element={<Logout/>} />
        <Route path="/session-timeout" element={<SessionTimeout/>} />
        <Route path="/" element={<Welcome />}/>
      </Routes>
      )}
      {userState?.currentUserSession && !userState?.currentUserSession?.hasAccess && (
         <Unauthorized/>
      )}
    </>
    
  ) : (
     <div>Not authorized</div>
    // <Routes>
    //    <Route path="/logout" element={<Logout/>} />
    // </Routes>
  );

  // return (
  //   <Router>
  //         {!CookieUtil.get('UserSignedInStatus')||CookieUtil.get('UserSignedInStatus')=="SIGNED_IN"||CookieUtil.get('UserSignedInStatus')=="SIGNING_IN"? (
  //          <MsalAuthenticationTemplate
  //           interactionType={InteractionType.Redirect}
  //           authenticationRequest={{
  //             scopes: ['User.Read']
  //           }}>
  //             {isAuthenticated && (
  //              <Routes>
  //               <Route path="/" element={<Welcome />}/>
  //               <Route path="/chat" element={<GPOHome/>} />
  //              </Routes>
  //             )}
              
  //         </MsalAuthenticationTemplate>
  //         ) : (
  //           <Logout/>
  //         )}
  //   </Router>
  // )
};

export default App;



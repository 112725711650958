import React from 'react';
import './Button.scss';

type ButtonProps = {
    variant?: 'primary' | 'primary-alt' |'secondary' |'warn' |'text' |'text-alt';
    children: React.ReactNode;
    size?: 'small' | 'medium' | 'large';
    onClick: () => void;
}
const Button = ({variant = 'primary', size = 'small', onClick, children}:ButtonProps) => {

    const getVariantClass = () =>{
        switch (variant) {
            case 'primary':
            return'gpo-button-primary';
            case 'primary-alt':
            return 'gpo-button-primary-alt';
            case 'secondary':
            return 'gpo-button-secondary';
            case 'warn':
            return 'gpo-button-warn';
            default:
                return 'gpo-button-primary';
        }
    }

    const getSizeClass = () =>{
        switch (size) {
            case 'medium':
            return 'gpo-button-medium';
            case 'large':
            return 'gpo-button-large';
            case 'small':
            return 'gpo-button-small';
        }
    }

    return (
        <button className={`${getVariantClass()} ${getSizeClass()} gpo-button `} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;
import Header from "modules/gpo/components/Header/Header";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { session_timeout_light } from "assets/images";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";
import "./SessionTimeout.scss";

const SessionTimeout: FC = ({}) => {
  const navigate = useNavigate();
  const userStateDispatch = useAppDispatch();
  const userState = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    window.history.pushState(null, "null", window.location.href);
    window.onpopstate = function (event) {
      window.history.pushState(null, "null", window.location.href);
    };
  }, []);

  const navigateToLogin = () => {
    userStateDispatch(userActions.updateUserState(true));
    window.location.replace(window.location.origin);
  };

  return (
    <div className="session-timeout-container">
      <Header
        showProfileImage={false}
        showSearch={false}
        showHistoryButton={false}
      />
      <div className="session-timeout-container__content">
        <div className="content-wrapper">
          <div className="content-wrapper__logo">
            <img className="logo-image" src={session_timeout_light} />
          </div>
          <div className="content-wrapper__message">
            <div className="message-title">
              <span>Your session has timed out!</span>
            </div>
            <div className="message-body">
              <span>Click below to login again</span>
            </div>
          </div>
          <div className="content-wrapper__action">
            <button onClick={() => navigateToLogin()}>Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionTimeout;

import React from 'react';
import './ModalBody.scss';

type ModalBodyProps = {
    children: React.ReactNode;
    className?: string;
}
const ModalBody = ({className = '', children}: ModalBodyProps) => {
    return (
        <div className={`modal-body ${className}`}>
            {children}
        </div>
    );
};

export default ModalBody;
import React, { FC, useCallback, useEffect, useRef, useState, memo, useLayoutEffect } from "react";
import { hooks } from "botframework-webchat-component";
import moment from "moment";

import {
  like,
  like_fill,
  gpo_logo,
  open_green,
  open_orange,
  open_red,
  logout,
} from "assets/images";

import "./ChatHistory.scss";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { chatHistoryActions } from "modules/gpo/redux/chatHistory";
import { ChatHistoryDetail, ChatHistoryModel, UserChatHistory } from "common/models/chat/chat-history.model";
import { ChatMessageType } from "common/enums/chat-message-types.enum";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import { ChatMessages } from "common/enums/chat-messages.enum";
import HighlightText from "../HighlightText/HighlightText";
import Divider from "../Divider/Divider";
import { IDocumentPage } from "common/models/chat/reference-document.model";
import Popup from "../Popup/Popup";
import { chatActions } from "modules/gpo/redux/chat";

  type ChatHistoryProps = {
     searchText?:string;
     onViewDoc: (pageNum: IDocumentPage[], documentGuid:string,activityId:string,documentName:string) => void;
  };
  const ChatHistory: FC<ChatHistoryProps> = ({
    searchText,
    onViewDoc
  }: ChatHistoryProps) => {

    const chatHistoryState = useAppSelector((state: RootState) => state.chatHistory);
    const chatHistoryDispatch = useAppDispatch(); 
    const [chatHistoryMessages, setChatHistoryMessages] = useState<ChatHistoryDetail[]>([]);
    const [groupedChatHistory,setGroupedChatHistory]= useState<any>(null);
    const [showPopup,setShowPopup]= useState<string>("");
    const popupRef = useRef<HTMLDivElement>(null);
    
    // useEffect(()=>{
    //   chatHistoryDispatch(chatHistoryActions?.getChatHistory(""));
    // },[])
    const handleClickOutside = (e: any) => {
      if (popupRef.current) {
        if (!popupRef.current.contains(e.target)) {
          setShowPopup("");
        }
      }
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    },[]);
    useEffect(()=>{
      if(chatHistoryState?.selectedConversation?.isSuccess){
        const historyMessages = chatHistoryState?.selectedConversation?.messages;
        setChatHistoryMessages(historyMessages);
        
      }
    },[chatHistoryState?.selectedConversation])


    const checkDay = (date): string => {
      if(moment.utc(date)?.local().isSame(moment(), "day")){
        return "Today "+moment.utc(date)?.local().format("LT");
      }
      else if(moment.utc(date)?.local().isSame(moment().subtract(1, 'day'), "day")){
        return "Yesterday "+moment.utc(date)?.local().format("LT");
      }
      else if(moment(date).isSame(moment(new Date())?.startOf('day')?.toISOString(), 'week')){
        return moment.utc(date)?.local().format("dddd") +" "+moment.utc(date)?.local().format("LT");  
      }
      else{
        return moment.utc(date)?.local()?.format("DD MMM, YYYY")+" "+moment.utc(date)?.local().format("LT"); 
      }
    }
    

    return chatHistoryState?.selectedConversation?.isSuccess ?(
      <div className="gpo-chat-history">
        {chatHistoryMessages?.map((message:ChatHistoryDetail, i) => {
          const roundedMessageScore = Math.round(message?.score);

          return <React.Fragment key={'user-chat-history_'+message?.messageGuid}>
            {message?.messageTypeId==ChatMessageType.BOTMESSAGE && (
              <section className="bot" id={'history-'+message?.messageGuid}>
                <div>
                <header>
                  <img src={gpo_logo} />
                    <div className="name">
                      <strong>GPO ChatBot</strong>
                        <span>{checkDay(message?.messageCreatedDate)}</span>
                    </div>
                </header>
                <div className="message-section">
                  <div className="message-section__wrapper">
                   < HighlightText
                      searchText={searchText}
                      displayText={message?.messageText?.replace(/\n/g, "</br>")}
                      disableTextHighlight={true}
                    />
                    <div className="message-footer">
                      <div className="gpo-icon" onClick={(event) => setShowPopup(message?.messageGuid)}>
                          {roundedMessageScore ? (
                            <>
                              {roundedMessageScore <= 60 && <img src={open_red} />}
                              {roundedMessageScore > 60 && roundedMessageScore < 80 && (
                                <img className="gpo-icon--img" src={open_orange} />
                              )}
                              {roundedMessageScore >= 80 && <img src={open_green} />}
                            </>
                          ) : ''}  
                        {showPopup==message?.messageGuid && (
                      <div className="gpo-pop-up">
                      {(roundedMessageScore &&
                      <div ref={popupRef}>
                      <Popup
                        onViewDoc={onViewDoc}
                        similarityScore={roundedMessageScore}
                        activityId={message?.messageGuid}
                        documents={message.documents}
                      />
                      </div>
                      )}
                      </div>
                      )}              
                      </div>
                    </div>
                  </div>
                    {message?.isMessageLiked==null && (
                    <div className="user-action">
                        <button><img src={like} /></button>
                        <button><img src={like} /></button>
                    </div>
                    )}
                    {message?.isMessageLiked!=null && message?.isMessageLiked && (
                    <div className="user-action">
                        <button><img src={like_fill} /></button>
                        <button><img src={like} /></button>
                    </div>
                    )}
                    {message?.isMessageLiked!=null && !message?.isMessageLiked && (
                      <div className="user-action">
                          <button><img src={like} /></button>
                          <button><img src={like_fill} /></button>
                      </div>
                    )}
                </div>
                </div>
                </section>
              )}
              {message?.messageTypeId==ChatMessageType.CLIENTMESSAGE && (
                <section className="user" id={'history-'+message?.messageGuid}>
                  <header>
                      <div className="name">
                        <strong>You</strong>
                          <span>{checkDay(message?.messageCreatedDate)} </span>
                      </div>
                  </header>
                  <HighlightText
                    searchText={searchText}
                    displayText={message?.messageText?.replace(/\n/g, "</br>")}
                    disableTextHighlight={true}
                   />
                </section>
               )}
                {message?.messageTypeId==ChatMessageType.EVENTMESSAGE && message?.messageText==ChatMessages.AGENT_JOINED &&( 
                  <section className="new-agent" id={'history-'+message?.messageGuid}>
                      <div className="detail">
                        <ProfileImage profileId={message?.user?.guid} />
                           <span>
                            <strong>{message?.user?.displayName}</strong> joined the chat
                          </span>
                          <span>{checkDay(message?.messageCreatedDate)} </span>
                      </div>
                 </section> 
                )}
                {message?.messageTypeId==ChatMessageType.AGENTMESSAGE && (
                    <section className="agent" id={'history-'+message?.messageGuid}>
                      <header>
                      <ProfileImage profileId={message?.user?.guid} />
                      <div className="name">
                      <strong>{message?.user?.displayName}</strong>
                        <span>{checkDay(message?.messageCreatedDate)}</span>
                      </div>
                      </header>
                      <div className="message-section">
                        <div className="message-section__wrapper">
                          < HighlightText
                              searchText={searchText}
                              displayText={message?.messageText?.replace(/\n/g, "</br>")}
                              disableTextHighlight={true}
                            />
                          <div className="message-footer">
                            <div className="gpo-icon" onClick={(event) => setShowPopup(message?.messageGuid)}>
                                {roundedMessageScore ? (
                                  <>
                                    {roundedMessageScore <= 60 && <img src={open_red} />}
                                    {roundedMessageScore > 60 && roundedMessageScore < 80 && (
                                      <img className="gpo-icon--img" src={open_orange} />
                                    )}
                                    {roundedMessageScore >= 80 && <img src={open_green} />}
                                  </>
                                ) : ''}  
                              {showPopup==message?.messageGuid && (
                            <div className="gpo-pop-up">
                            {(roundedMessageScore &&
                            <div ref={popupRef}>
                            <Popup
                              onViewDoc={onViewDoc}
                              similarityScore={roundedMessageScore}
                              documents={message.documents}
                              activityId={message?.messageGuid}
                            />
                            </div>
                            )}
                            </div>
                            )}              
                            </div>
                          </div>
                        </div>
                    {message?.isMessageLiked==null && (
                    <div className="user-action">
                        <button><img src={like} /></button>
                        <button><img src={like} /></button>
                    </div>
                    )}
                    {message?.isMessageLiked!=null && message?.isMessageLiked && (
                    <div className="user-action">
                        <button><img src={like_fill} /></button>
                        <button><img src={like} /></button>
                    </div>
                    )}
                    {message?.isMessageLiked!=null && !message?.isMessageLiked && (
                      <div className="user-action">
                          <button><img src={like} /></button>
                          <button><img src={like_fill} /></button>
                      </div>
                    )}
                </div>                 
                    </section>                     
                )}
                {message?.messageTypeId==ChatMessageType.EVENTMESSAGE && message?.messageText==ChatMessages.AGENT_LEFT &&(  
                  <section className="agent-left" id={'history-'+message?.messageGuid}>
                    <div className="center">
                      <img src={logout} />
                        <span>
                          <strong>{message?.user?.displayName}</strong> left the chat
                        </span>
                    </div>
                  </section>
                )}
              </React.Fragment>
        })}
          
        {chatHistoryMessages?.length && groupedChatHistory && !groupedChatHistory["Today"] &&(
           <Divider dividerText={"Today"} />    
        )}       
      </div>
    ):<></>;
  }

  export default memo(ChatHistory);

import React from 'react';
import './ModalHeader.scss';
import IconButton from '../../IconButton/IconButton';
import { close_icon_modal } from 'assets/images';

type ModalHeaderProps = {
    children: React.ReactNode;
    onClose?: () => void;
}
const ModalHeader = ({children, onClose}: ModalHeaderProps) => {
    return (
        <div className='modal-header '>
        <h3 className="modal-headline">{children}</h3>
        <IconButton className='modal--header-icon-button' iconSrc={close_icon_modal} onClick={onClose}/>
    </div>
    );
};

export default ModalHeader;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatHistoryDeleteResponse, ChatHistoryDetailsResponse, ChatHistoryModel,UserChatHistoryData, UserChatHistoryDataRequest } from 'common/models/chat/chat-history.model';
import { initialChatHistoryState } from './chatHistory.state';
import { ChatHistoryBookmarkRequest, ChatHistoryBookmarkResponse } from 'common/models/chat/chat-bookmark.model';


const chatHistorySlice = createSlice({
    name: 'chatHistory',
    initialState: initialChatHistoryState,
    reducers: {
        getChatHistory: (state, { payload }: PayloadAction<string>) => {
        },
        getChatHistorySuccess: (state, { payload }: PayloadAction<ChatHistoryModel>) => {
            state.chatHistory = payload;
        },
        getChatHistoryFailed: (state, { payload }: PayloadAction<any>) => {
            state.chatHistory = initialChatHistoryState.chatHistory;
            console.log("Errors",payload);
        },
        updateSearchTriggered: (state, { payload }: PayloadAction<boolean>) => {
            state.searchTriggered = payload;
        },
        updateSearchValue: (state, { payload }: PayloadAction<string>) => {
            state.searchValue = payload;
        },
        getSearchResults: (state, { payload }: PayloadAction<string>) => {
        },
        getSearchResultsSuccess: (state, { payload }: PayloadAction<ChatHistoryModel>) => {
            state.searchResults = payload;
        },
        getSearchResultsFailed: (state, { payload }: PayloadAction<any>) => {
            state.searchResults = initialChatHistoryState.searchResults;
            console.log("Errors",payload);
        },
        clearSearchResults : (state) => {
            state.searchResults = initialChatHistoryState.searchResults;
        },
        getChatHistoryData: (state, { payload }: PayloadAction<UserChatHistoryDataRequest|undefined>) => {
        },
        getChatHistoryDataSuccess: (state, { payload }: PayloadAction<UserChatHistoryData>) => {
            state.chatHistoryData = payload;
        },
        getChatHistoryDataFailed: (state, { payload }: PayloadAction<any>) => {
            if (!state.chatHistoryData) {
                state.chatHistoryData = {
                    isSuccess : false,
                    closedConversations:[]
                }
            }
            console.log("Errors",payload);
        }, 
        clearChatHistoryData : (state) => {
            state.chatHistoryData = undefined;
        },
        getSelectedChatHistoryDetails: (state, { payload }: PayloadAction<string|undefined>) => {
        },
        getSelectedChatHistoryDetailsSuccess: (state, { payload }: PayloadAction<ChatHistoryDetailsResponse>) => {
            state.selectedConversation = payload;
        },
        getSelectedChatHistoryDetailsFailed: (state, { payload }: PayloadAction<any>) => {
            if (!state.selectedConversation) {
                state.selectedConversation = {
                    isSuccess : false,
                    messages:[],
                    status:'Error',
                    code: payload?.statusCode ? payload?.statusCode:400,
                    message:"Error occured in retrieving chat history"
                }
            }
            console.log("Errors",payload);
        },
        clearSelectedConversation : (state) => {
            state.selectedConversation = undefined;
        },
        deleteSelectedChatHistoryDetails: (state,{ payload }: PayloadAction<Array<string>|undefined>) => {
        },
        deleteSelectedChatHistoryDetailsSuccess: (state, { payload }: PayloadAction<ChatHistoryDeleteResponse>) => {
            state.chatHistoryConversationDeleted = payload;
            state.selectedConversation = undefined;
        },
        deleteSelectedChatHistoryDetailsFailed: (state, { payload }: PayloadAction<any>) => {
            if (!state.chatHistoryConversationDeleted) {
                state.chatHistoryConversationDeleted = {
                    isSuccess : false
                }
            }
            console.log("Errors",payload);
        }, 
        clearDeleteSelectedConversation : (state) => {
            state.chatHistoryConversationDeleted = undefined;
        },
        bookmarkChat: (state,{ payload }: PayloadAction<ChatHistoryBookmarkRequest>) => {
        },
        bookmarkChatSuccess: (state, { payload }: PayloadAction<ChatHistoryBookmarkResponse>) => {
            state.chatBookmark = payload;
        },
        bookmarkChatFailed: (state, { payload }: PayloadAction<any>) => {
            if (!state.chatBookmark) {
                state.chatBookmark = {
                    status:"Error",
                    code : 500,
                    message:"Error"
                }
            }
            console.log("Errors",payload);
        }, 
        removeBookmark: (state,{ payload }: PayloadAction<string>) => {
        },
        removeBookmarkSuccess: (state, { payload }: PayloadAction<ChatHistoryBookmarkResponse>) => {
            if(!payload){
                state.removeBookmark = {
                    status:"OK",
                    code : 204,
                    message:"Bookmarked Removed"
                }
            }
            else{
             state.removeBookmark = payload;
            }
        },
        removeBookmarkFailed: (state, { payload }: PayloadAction<any>) => {
            if (!state.removeBookmark) {
                state.removeBookmark = {
                    status:"Error",
                    code : 500,
                    message:"Error"
                }
            }
            console.log("Errors",payload);
        }, 
       
    }
});

export const chatHistoryActions = chatHistorySlice.actions;

export default chatHistorySlice.reducer;

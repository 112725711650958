import { ICurrentUserDetails } from "common/models/user/current-user-details.model";
import { RequestAccessResponseModel } from "common/models/user/request-access.model";
import { IUserSessionDetails } from "common/models/user/user-session-details.model";

export interface IUserState {
    id: string;
    name: string;
    emailId: string;
    avatar: string;
    isAuthenticated: boolean;
    isTokenBlacklisted: boolean;
    userAvatar: string;
    userGuid :  string;
    botToken : string;
    currentUserSession:IUserSessionDetails|undefined,
    logoutSuccess:any,
    loggedIn:boolean,
    userDetails : ICurrentUserDetails|undefined,
    requestAccessResponse : RequestAccessResponseModel|undefined;
    isSessionTimedOut: boolean;
}

const initialUserState: IUserState = {
    name: '',
    emailId: '',
    id: '',
    avatar: '',
    isAuthenticated: false,
    isTokenBlacklisted: false,
    userAvatar: '',
    userGuid: '',
    botToken: '',
    currentUserSession: undefined,
    logoutSuccess: undefined,
    loggedIn: true,
    userDetails: undefined,
    requestAccessResponse: undefined,
    isSessionTimedOut: false
};

export { initialUserState };

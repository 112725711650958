import { ApiResult } from "common/models";
import { IChatFeedbackRequest } from "common/models/chat/chat-feedback-request.model";
import { HttpClient } from "common/services/http-client.service";
import chatApiConfig from "./chat.config";
import { SearchChatRequest } from "common/models/chat/chat-history.model";

export class ChatBotService extends HttpClient {
    public static async sendUserFeedback(request:IChatFeedbackRequest): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.sendUserFeedback(request));
    }
    public static async getPDFDocument(documentGuid:string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.getPDFDocument(documentGuid));
    }
    public static async searchChat(request:SearchChatRequest): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.searchChat(request));
    }
    public static async getPDFfile (documentGuid:string): Promise<ApiResult<any>> {
        return await this.invokeApi<any>(chatApiConfig.getPDFFile(documentGuid));
    }
}

import { right_arrow } from 'assets/images';
import React, { FC, useEffect, useState } from 'react';
import './Popup.scss';
import { IDocumentPage, IReferenceDocument } from 'common/models/chat/reference-document.model';
import { RootState, useAppDispatch, useAppSelector } from 'common/redux/core/root.reducer';
import { chatActions } from 'modules/gpo/redux/chat';
import DocumentListModal from '../DocumentListModal/DocumentListModal';

type PopupProps = {
    similarityScore: number;
    documents: IReferenceDocument[];
    activityId: string;
    onViewDoc: (pageNum: IDocumentPage[], documentGuid: string, activityId: string,documentName:string) => void;
};
const Popup: FC<PopupProps> = ({
    similarityScore,
    documents,
    activityId,
    onViewDoc
}: PopupProps) => {
    const chatStateDispatch = useAppDispatch();
    const chatState = useAppSelector((state: RootState) => state.chat);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);

    useEffect(() => {
        chatStateDispatch(chatActions?.setPdfDocsData({ documents, activityId }));
    }, []);

    useEffect(()=>{
        setTimeout(()=>{
            const messageDiv = document.getElementById('history-' + activityId)
            ? document.getElementById('history-' + activityId)
            : document.getElementById('live-chat_' + activityId);

            if ( messageDiv) {
                if(messageDiv?.querySelector(".gpo-tooltip__container")){
                    messageDiv?.querySelector(".gpo-tooltip__container")?.scrollIntoView({ block: 'end', inline: 'nearest' });
                }
                else if(messageDiv?.parentElement?.querySelector(".gpo-tooltip__container")){
                    messageDiv?.parentElement?.querySelector(".gpo-tooltip__container")?.scrollIntoView({ block: 'end', inline: 'nearest' });
                }
            }
        },100);
    },[activityId])

    const getStatus = () => {
        if (similarityScore <= 60) {
          return (
            <span className={`gpo-tooltip__status gpo-tooltip__status--poor`}>
              {similarityScore + "%"}
            </span>
          );
        } else if (similarityScore > 60 && similarityScore < 80) {
          return (
            <span className={`gpo-tooltip__status gpo-tooltip__status--fair`}>
              {similarityScore + "%"}
            </span>
          );
        } else if (similarityScore >= 80) {
          return (
            <span className={`gpo-tooltip__status gpo-tooltip__status--excellent`}>
              {similarityScore + "%"}
            </span>
          );
        }
    };

    const handleShowAllClick = () => {
        console.log('');
        setShowDocumentsModal(true);
    };

    const getDocumentsLink = () => {
        if (documents.length > 1) {
            return (
                <span>
                    {`${documents.length} Documents`}
                    <a onClick={handleShowAllClick}>Show all</a>
                </span>
            );
        } else {
            return <div className='gpo-tooltip__document-source'>
                <p>
                    <span>{documents[0]?.documentName}</span>&nbsp;
                    <span>{documents[0]?.version?.toUpperCase()}</span>
                </p>
                <p>{`(${documents[0]?.active ? 'Active Document' : 'Archived Document'})`}</p>
            </div>;
        }
    };

    const handleDocumentRowClick = (document: IReferenceDocument) => {
        setShowDocumentsModal(false);
        onViewDoc(document.pages, document.documentGuid, activityId,document.documentName);
    };

    console.log("popup", documents)

    return (
        <>
            {!chatState.isDocumentOpen && (
                <div className="gpo-tooltip">
                    <div className="gpo-tooltip__container">
                        <div className="gpo-tooltip__row">
                            <span className="gpo-tooltip__title">Confident score</span>
                            {getStatus()}
                        </div>
                        <div className="gpo-tooltip__row gpo-tooltip__row--source-data">
                            <span className="gpo-tooltip__title">Source</span>
                            <span className="gpo-tooltip__pages">
                                {getDocumentsLink()}
                            </span>
                        </div>
                        <div className="gpo-tooltip__row">
                            <span></span>
                            <span
                                className="gpo-tooltip__view"
                                onClick={() =>
                                    onViewDoc(
                                        documents[0]?.pages,
                                        documents[0]?.documentGuid,
                                        activityId,
                                        documents[0]?.documentName
                                    )
                                }>
                                View in document <img src={right_arrow} />
                            </span>
                        </div>
                    </div>
                </div>
            )}

            {showDocumentsModal && (
                <DocumentListModal
                    documents={documents}
                    onRowClick={handleDocumentRowClick}
                    onClose={() => setShowDocumentsModal(false)}
                />
            )}
        </>
    );
};

export default Popup;

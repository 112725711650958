import { APP_CONTROLLER, METHOD } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";
import { IChatFeedbackRequest } from "common/models/chat/chat-feedback-request.model";
import { SearchChatRequest } from "common/models/chat/chat-history.model";


const chatApiConfig = {
 
   sendUserFeedback: (feedbackRequest:IChatFeedbackRequest): ApiConfig<any> =>
      new ApiConfig(`${APP_CONTROLLER.CHAT}/${METHOD.FEEDBACK}`, 'POST','',feedbackRequest),
    
   getPDFDocument: (documentGuid:string): ApiConfig<any> =>
      new ApiConfig(`${APP_CONTROLLER.DOCUMENTS}/${documentGuid}`, 'GET'),

   searchChat: (request:SearchChatRequest): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.MESSAGES}/${METHOD.SEARCH}`, 'POST','',request),
   getPDFFile : (documentGuid:string) :ApiConfig<any> =>
       new ApiConfig(`${METHOD.MANAGED_DOCUMENTS}/${METHOD.DOWNLOAD}`, 'POST', "", {guidCode:documentGuid},undefined,"blob"),
       
};

export default chatApiConfig;

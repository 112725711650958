import React, { FC, useCallback, useEffect, useRef, useState, memo } from 'react';
import { hooks } from 'botframework-webchat-component';
import moment from 'moment';
import getValueOrUndefined from 'common/utils/utility';
import Popup from '../Popup/Popup';
import Attachment from '../Attachment/Attachment';

import {
    like,
    like_fill,
    gpo_logo,
    open_green,
    open_orange,
    open_red,
    logout,
    feedback_icon
} from 'assets/images';

import './Chat.scss';
import { defaultUserIcon } from 'assets/media';
import { IChatFeedbackRequest } from 'common/models/chat/chat-feedback-request.model';
import { useSelector, useDispatch } from 'react-redux';
import { IDocumentPage } from 'common/models/chat/reference-document.model';
import { CustomBotEventTypes } from 'common/enums/chatbot-event-types.enum';
import { IChatFeedbackEvent } from 'common/models/chat/chat-feedback-event.model';
import { ENV_CONFIG } from 'common/config/env.config';
import ProfileImage from 'common/components/custom/ProfileImage/ProfileImage';
import { RootState, useAppDispatch, useAppSelector } from 'common/redux/core/root.reducer';
import { chatActions } from 'modules/gpo/redux/chat';
import ChatHistory from '../ChatHistory/ChatHistory';
import { AcknowledgmentType } from 'common/enums/acknowledgment-type.enum';
import ConnectingLoader from '../ConnectingLoader/ConnectingLoader';
import { ChatUserDetails, UserChatHistory } from 'common/models/chat/chat-history.model';
import { ChatMessageType } from 'common/enums/chat-message-types.enum';
import { chatHistoryActions } from 'modules/gpo/redux/chatHistory';
import DotLoader from 'common/components/base/DotLoader/DotLoader';
import { ApiStatus } from 'common/enums';
import { msalInstance, protectedResources } from "common/config/auth.config";
const { useActivities, useSendMessage, useSendEvent, useSendTimeoutForActivity } = hooks;
type ChatProps = {
    onViewDoc: (pageNum: IDocumentPage[], documentGuid: string, activityId: string,documentName:string) => void;
};
const Chat: FC<ChatProps> = ({ onViewDoc }: ChatProps) => {
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const clearAgentTimerRef: any = useRef(null);

    const [activities] = useActivities();
    const sendMessage = useSendMessage();
    const sendEvent = useSendEvent();
    const [sendBoxValue, setSendBoxValue] = useState('');

    const [sequenceId, setSequenceId] = useState('');
    const [currentTime, setCurrentTime] = useState(0);
    const [reactions, setReactions] = useState<any>([]);
    const popupRef = useRef<HTMLDivElement>(null);

    const [profileImage, setProfileImage] = useState(defaultUserIcon);
    const [agentGuid, setAgentGuid] = useState('');
    const [agentTransferButtons, setAgentTransferButtons] = useState(true);
    const [hasAgentTimer, setHasAgentTimer] = useState(false);
    const [agentJoinedGuid, setAgentJoinedGuid] = useState('');
    const [handsOffTriggered, setHandsOffTriggered] = useState(false);

    const chatState = useAppSelector((state: RootState) => state.chat);
    const chatStateDispatch = useAppDispatch();
    const chatHistoryState = useAppSelector((state: RootState) => state.chatHistory);
    const userState = useAppSelector((state: RootState) => state.user);
    const showResponse: any = ENV_CONFIG.SHOW_ALTERNATE_RESPONSE;
    const showAlternateResponse = JSON.parse(showResponse);
     const [ssoToken,setSSOToken] = useState<string>("");
    const [stopCurrentTyping, setStopCurrentTyping] = useState(false);
    const welcomeMessage =
        "Hello! I'm here to assist you with any HR and payroll inquiries you might have. I can provide guidance based on policy documents as well as take into account your unique HR and payroll data.";

    useEffect(() => {
        document.addEventListener('visibilitychange', (event) => {
            if (document.visibilityState == 'visible') {
                setStopCurrentTyping(false);
            } else {
                setStopCurrentTyping(true);
            }
        });
    }, []);
useEffect(()=>{
  getToken();
 },[])
  const getToken = async () =>{
    const acquireTokenResult = await msalInstance.acquireTokenSilent(
      {
       scopes: protectedResources.api.scopes
      });
      if(acquireTokenResult){
        setSSOToken(acquireTokenResult?.accessToken);
      }
  }

    useEffect(() => {
        if (chatState?.chatSelected)
            chatStateDispatch(
                chatHistoryActions?.getSelectedChatHistoryDetails(chatState?.chatSelected)
            );
    }, [chatState?.chatSelected]);

    useEffect(() => {
        if ((userState?.botToken && chatHistoryState?.chatHistory, activities)) {
            if (chatHistoryState?.chatHistory?.clientChatHistories?.length || activities?.length) {
                chatStateDispatch(chatActions.updateBotConnectingStatus(ApiStatus.SUCCESS));
            }
        }
    }, [userState?.botToken, chatHistoryState?.chatHistory, activities]);

    let agentJoiningTimer;
    const handleChange = useCallback(
        ({ target: { value } }: any) => setSendBoxValue(value),
        [setSendBoxValue]
    );

    const handleClickOutside = (e: any) => {
        if (popupRef.current) {
            if (!popupRef.current.contains(e.target)) {
                setSequenceId('');
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (
            messagesEndRef.current &&
            activities?.length &&
            activities[activities?.length - 1]?.type != 'event'
        ) {
            const searchValue: any = document?.getElementById('history-search');

            if (!chatHistoryState?.selectedConversation && !searchValue?.value) {
                messagesEndRef.current.scrollIntoView({
                    behavior: "smooth"
                   // block: 'start',
                    //inline: 'nearest'
                });
            }
        }
        const activitiesList: any = activities;
        const currentUser: any = {
            guid: userState.userDetails?.guid,
            displayName: userState.userDetails?.displayName,
            emailId: userState.userDetails?.emailId,
            createdDate: '',
            userIdentity: undefined,
            roles: userState.userDetails?.userRoles
        };
        activitiesList
            ?.filter(({ type }) => type === 'message')
            .map((activityItem: any) => {
                if (activityItem?.id) {
                    const searchItem: UserChatHistory = {
                        additionalInfo: activityItem?.summary
                            ? JSON.parse(activityItem?.summary)?.additionalInfo
                            : null,
                        isMessageLiked: false,
                        messageGuid: activityItem?.id,
                        messageType:
                            activityItem?.from?.role == 'user'
                                ? ChatMessageType?.CLIENTMESSAGE
                                : activityItem?.from?.role == 'bot' &&
                                  activityItem?.summary &&
                                  JSON.parse(activityItem?.summary)?.userDetails
                                ? ChatMessageType.AGENTMESSAGE
                                : ChatMessageType.BOTMESSAGE,
                        messageText: activityItem?.text,
                        messageCreatedDate: activityItem?.timestamp,
                        user:
                            activityItem?.summary && JSON.parse(activityItem?.summary)?.userDetails
                                ? JSON.parse(activityItem?.summary)?.userDetails
                                : activityItem?.from?.role == 'user'
                                ? currentUser
                                : {
                                      displayName: 'Bot',
                                      guid: activityItem?.from?.id,
                                      emailId: 'Bot@gds.ey.com'
                                  },
                        isAnnotated: false,
                        fromHistory: false
                    };
                    const chatResults = chatState?.chatSearchResults?.filter(
                        (result: UserChatHistory) => !result?.fromHistory
                    );
                    if (
                        !chatState?.chatSearchResults?.some(
                            (el) => el.messageGuid === activityItem.id
                        )
                    ) {
                        chatStateDispatch(chatActions?.updateChatResults(searchItem));
                    }
                }
            });
        const lastActivity: any = activities[activities?.length - 1];
        const lastActivitySummary = lastActivity?.summary
            ? JSON.parse(lastActivity?.summary)
            : null;
        const isFeedbackSubmitted =
          lastActivity?.name === "RatingResponseEvent" &&
          lastActivity?.value?.IsSuccess;

        if (lastActivitySummary && lastActivitySummary?.isAgentJoined) {
            setAgentJoinedGuid(lastActivitySummary?.userDetails?.guid);
            chatStateDispatch(chatActions?.updateMessageInputState(true));
        }
        if (lastActivitySummary && lastActivitySummary?.isAgentLeft) {
            setAgentJoinedGuid('');
            chatStateDispatch(chatActions?.updateMessageInputState(false));
        }
        if (lastActivitySummary && lastActivitySummary?.isAgentReject) {
            setAgentJoinedGuid('');
            chatStateDispatch(chatActions?.updateMessageInputState(false));
        }
        //for new welcome message
        if (lastActivitySummary && lastActivitySummary?.isWelcomeMessage) {
            let activityToBeChanged: any = lastActivity;
            activityToBeChanged.text = welcomeMessage;
            activities[activities.length - 1] = activityToBeChanged;
        }
        // to show confirmation toast on feedback submission
        if(isFeedbackSubmitted) {
          console.log('Feedback submitted successfully');
        }
        // const element = document.getElementsByClassName('gpo-custom-chat-messages')[0];
        // element.scrollTo(0,element.scrollHeight);
    }, [activities]);

    useEffect(() => {
        const timeInterval = setInterval(() => {
            setCurrentTime(Date.now());
        }, 60000);
        return () => {
            clearInterval(timeInterval);
        };
    }, []);

    useEffect(() => {
        if (chatState?.handsOffAcknowledged?.length && activities?.length) {
            const lastHandsOff =
                chatState?.handsOffAcknowledged[chatState?.handsOffAcknowledged?.length - 1];
            const lastActivity: any = activities[activities?.length - 1];
            if (lastHandsOff && lastActivity) {
                if (
                    ENV_CONFIG.SHOW_AGENT_TIMER &&
                    lastHandsOff?.message == 'Yes' &&
                    lastActivity?.type == 'event' &&
                    lastActivity?.name == 'handoff.initiate' &&
                    !hasAgentTimer
                ) {
                    setHasAgentTimer(true);
                    startAgentJoiningTimer();
                }
                if (
                    ENV_CONFIG.SHOW_AGENT_TIMER &&
                    lastHandsOff?.message == 'Yes' &&
                    lastActivity?.summary &&
                    (JSON.parse(lastActivity?.summary)?.isAgentJoined ||
                        (lastActivity?.summary && JSON.parse(lastActivity?.summary)?.isAgentReject))
                ) {
                    setHandsOffTriggered(false);
                    chatStateDispatch(chatActions?.updateMessageInputState(true));
                    setHasAgentTimer(false);
                    if (clearAgentTimerRef.current) {
                        clearTimeout(clearAgentTimerRef.current);
                    }
                }
            }
        }
    }, [chatState?.handsOffAcknowledged, activities]);

    const handleSubmit = useCallback(
        (event: any) => {
            event.preventDefault();
            sendMessage(sendBoxValue,'',{channelData:{token:ssoToken}});
            setSendBoxValue('');
            //chatStateDispatch(chatActions?.updateStopTyping(false));
            if (!agentJoinedGuid) chatStateDispatch(chatActions?.updateMessageInputState(false));
        },
        [sendBoxValue, sendMessage, setSendBoxValue]
    );

    const handleAgentAcknowledgement = useCallback(
        (event: any, message: string, activity: any, acknowledgmentType: AcknowledgmentType) => {
            event.preventDefault();
            setSendBoxValue(message);
            sendMessage(message);
            setSendBoxValue('');
            let activitySummary = activity?.summary ? JSON.parse(activity?.summary) : undefined;
            if (activitySummary) {
                activitySummary.showHandOffOptions = false;
                activity.summary = JSON.stringify(activitySummary);
            }
            if (acknowledgmentType == AcknowledgmentType.AGENT_TRANSFER) {
                if (message == 'Yes') {
                    setHandsOffTriggered(true);
                }
                chatStateDispatch(
                    chatActions?.updateChatHandsOffState({
                        id: activitySummary?.additionalInfo?.chatResponseGuid,
                        message: message
                    })
                );
            }
            if (acknowledgmentType == AcknowledgmentType.AGENT_LEAVING) {
                chatStateDispatch(
                    chatActions?.updateAgentLeavingAcknowledgedState({
                        id: activity?.id,
                        message: message
                    })
                );
                setAgentJoinedGuid('');
            }
        },
        [setSendBoxValue, sendMessage, setSendBoxValue]
    );

    const startAgentJoiningTimer = () => {
        clearAgentTimerRef.current = window.setTimeout(function () {
            handleFeedback(CustomBotEventTypes.CLOSEHANDOFF, '');
            console.log('stop timer');
            setHandsOffTriggered(false);
            setHasAgentTimer(false);
        }, ENV_CONFIG.AGENT_HANDOFF_TIMER);
    };

    const handleFeedback = useCallback(
        (name: any, value: any, event?: any) => {
            event?.preventDefault();
            sendEvent(name, value);
        },
        [sendEvent]
    );

    const handleClick = (activity: any) => {
        setSequenceId(activity?.channelData['webchat:sequence-id']);
    };

    const handleReaction = (activity: any, sequenceId: string, reaction: number) => {
        let reactObj = {
            id: sequenceId,
            reaction: reaction
        };
        let reactionsObj = reactions;
        const reactionList = reactions.filter((i: any) => i.id === sequenceId);
        if (reactionList.length) {
            let el = reactions.map((item: any) => {
                if (item.id === sequenceId) {
                    item.reaction = reaction;
                }
                return item;
            });
            setReactions(el);
        } else {
            setReactions((prev: any) => [...prev, reactObj]);
            reactionsObj = [...reactionsObj, reactObj];
        }
        //sendChatfeedback(activity,reaction);
        const messageActivities = activities?.length
            ? activities?.filter((activity) => activity.type == 'message')
            : [];
        const feedbackResponse: IChatFeedbackEvent = {
            messageGuid: activity?.summary
                ? JSON.parse(activity?.summary)?.additionalInfo?.chatResponseGuid
                : '',
            suggestAlternative:
                showAlternateResponse &&
                activity?.summary &&
                !JSON.parse(activity?.summary)?.userDetails &&
                reaction == 2 &&
                messageActivities?.length &&
                messageActivities[messageActivities?.length - 1]?.id == activity.id
                    ? true
                    : false
        };
        if (feedbackResponse?.suggestAlternative) {
            chatStateDispatch(chatActions?.updateMessageInputState(false));
        }
        handleFeedback(
            reaction == 1 ? CustomBotEventTypes.LIKE : CustomBotEventTypes.DISLIKE,
            feedbackResponse
        );
    };

    const sendChatfeedback = (activity: any, reaction: number) => {
        if (activity.summary && JSON.parse(activity.summary)?.additionalInfo?.chatResponseGuid) {
            let chatFeedback: IChatFeedbackRequest = {
                messageGuid: JSON.parse(activity.summary)?.additionalInfo?.chatResponseGuid,
                like: reaction == 1 ? true : false,
                feedBack: '',
                handOff: false
            };
            chatStateDispatch(chatActions?.sendUserFeedback(chatFeedback));
        }
    };

    const getReaction = (sequenceId: string) => {
        const reactionObj = reactions.find((i: any) => i.id == sequenceId);
        if (reactionObj) {
            return reactionObj.reaction;
        }
    };

    const getIcon = (activity: any) => {
        if (activity?.summary) {
            let res = JSON.parse(activity?.summary)?.additionalInfo?.results
                ? JSON.parse(activity?.summary)?.additionalInfo.results[0]
                : null;
            
            if (res && !JSON.parse(activity?.summary)?.showHandOffOptions) {
                //if (res.page !== 0 || res.score !== 0) {
                if (res.score !== 0) {
                    const roundedScore = Math.round(res.score);
                    if (roundedScore <= 60) {
                        return <img src={open_red} />;
                    } else if (roundedScore > 60 && roundedScore < 80) {
                        return <img src={open_orange} />;
                    } else if (roundedScore >= 80) {
                        return <img src={open_green} />;
                    }
                }
            }
        }
    };
    const getPopup = (activity: any) => {
        if (activity?.summary) {
            let res = JSON.parse(activity?.summary)?.additionalInfo?.results
                ? JSON.parse(activity?.summary)?.additionalInfo?.results[0]
                : null;
            return (
                <div className="gpo-pop-up">
                    {activity?.channelData['webchat:sequence-id'] == sequenceId && (
                        <div ref={popupRef}>
                            <Popup
                                onViewDoc={onViewDoc}
                                similarityScore={Math.round(res?.score)}
                                documents={res?.documents}
                                activityId={activity?.id}
                            />
                        </div>
                    )}
                </div>
            );
        }
    };
    const handleScroll = (event) => {
        chatStateDispatch(chatActions?.updateChatScrolled(true));
    };
    return (
        <div className="gpo-custom-bot">
            {chatState.searchMessages.status === ApiStatus.LOADING && <DotLoader fullScreen />}

            <div className="gpo-custom-chat-messages" onWheel={(event) => handleScroll(event)}>
                <section className="gpo-chatbot-container__banner">
                    <img src={gpo_logo} />
                    <h1>Welcome to GPO ChatBot!</h1>
                    <p>You can ask the chatbot sample questions as below:</p>
                    <ul>
                        <li>
                            When will I receive my salary and what happens if my payday is a holiday
                            in India?
                        </li>
                        <li>
                            In Hungary what is the total employee contribution to social security?
                        </li>
                        <li>What are the deductions in salary in Korea?</li>
                    </ul>
                </section>
                {chatState.botConnectingStatus == ApiStatus.LOADING && <ConnectingLoader />}

                <div className="gpo-chat-wrapper">
                    <ChatHistory onViewDoc={onViewDoc} />
                    <>
                        {chatState.botConnectingStatus != ApiStatus.LOADING && (
                            <>
                                {activities
                                    .filter(({ type }) => type === 'message')
                                    .map((activity: any) => ({
                                        ...activity,
                                        attachments: activity.attachments || [],
                                        text:
                                            getValueOrUndefined(
                                                activity,
                                                'channelData',
                                                'messageBack',
                                                'displayText'
                                            ) || activity.text
                                    }))
                                    .filter(({ attachments, text }) => attachments.length || text)
                                    .map((activity, index, list) => (
                                        <React.Fragment key={activity.id || index}>
                                            <div
                                                className={
                                                    chatHistoryState?.selectedConversation
                                                        ? 'hide gpo-chat-wrapper__list'
                                                        : 'gpo-chat-wrapper__list'
                                                }>
                                                {activity?.summary &&
                                                    JSON.parse(activity?.summary)
                                                        ?.isAgentJoined && (
                                                        <div className="agent-wrapper">
                                                            <div className="agent-wrapper__container">
                                                                <ProfileImage
                                                                    profileId={
                                                                        JSON.parse(
                                                                            activity?.summary
                                                                        )?.userDetails?.guid
                                                                    }
                                                                />
                                                                <span>
                                                                    <strong>
                                                                        {
                                                                            JSON.parse(
                                                                                activity?.summary
                                                                            )?.userDetails
                                                                                ?.displayName
                                                                        }
                                                                    </strong>{' '}
                                                                    joined the chat
                                                                </span>
                                                                <span>
                                                                    {moment(
                                                                        activity.timestamp
                                                                    ).fromNow()}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                {activity?.summary &&
                                                    JSON.parse(activity?.summary)?.isAgentLeft && (
                                                        <div className="agent-logout-wrapper">
                                                            <div className="agent-logout-wrapper__container">
                                                                <span>
                                                                    <img src={logout} />
                                                                    <strong>
                                                                        {activity?.summary &&
                                                                            JSON.parse(
                                                                                activity?.summary
                                                                            )?.userDetails
                                                                                ?.displayName}
                                                                    </strong>{' '}
                                                                    left the chat
                                                                </span>
                                                                <div className="action">
                                                                    Did your query get clarified?
                                                                    {activity?.summary &&
                                                                        !chatState?.agentLeavingAcknowledged.some(
                                                                            (item) =>
                                                                                item.id ===
                                                                                activity?.id
                                                                        ) && (
                                                                            <span>
                                                                                <button
                                                                                    onClick={(
                                                                                        event
                                                                                    ) =>
                                                                                        handleAgentAcknowledgement(
                                                                                            event,
                                                                                            'Yes',
                                                                                            activity,
                                                                                            AcknowledgmentType.AGENT_LEAVING
                                                                                        )
                                                                                    }>
                                                                                    Yes
                                                                                </button>
                                                                                <button
                                                                                    onClick={(
                                                                                        event
                                                                                    ) =>
                                                                                        handleAgentAcknowledgement(
                                                                                            event,
                                                                                            'No',
                                                                                            activity,
                                                                                            AcknowledgmentType.AGENT_LEAVING
                                                                                        )
                                                                                    }>
                                                                                    No
                                                                                </button>
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                <div
                                                    className={
                                                        activity.from.role == 'bot'
                                                            ? 'gpo-bot'
                                                            : 'gpo-user'
                                                    }>
                                                    <div className="gpo-chats">
                                                        {(!activity?.summary ||
                                                            (activity?.summary &&
                                                                !JSON.parse(activity.summary)
                                                                    ?.isAgentJoined &&
                                                                !JSON.parse(activity.summary)
                                                                    ?.isAgentLeft)) && (
                                                            <div className="gpo-message-section">
                                                                {activity.from.role == 'bot' &&
                                                                    !activity?.summary && (
                                                                        <div className="gpo-bot-avatar">
                                                                            <img src={gpo_logo} />
                                                                            <span>
                                                                                GPO ChatBot
                                                                                <span className="gpo-time">
                                                                                    {moment(
                                                                                        activity.timestamp
                                                                                    ).fromNow()}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                {activity.from.role == 'bot' &&
                                                                    activity?.summary &&
                                                                    (!JSON.parse(activity?.summary)
                                                                        ?.userDetails ||
                                                                        JSON.parse(
                                                                            activity?.summary
                                                                        )?.isAgentReject) && (
                                                                        <div className="gpo-bot-avatar">
                                                                            <img src={gpo_logo} />
                                                                            <span>
                                                                                GPO ChatBot
                                                                                <span className="gpo-time">
                                                                                    {moment(
                                                                                        activity.timestamp
                                                                                    ).fromNow()}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                {activity.from.role == 'bot' &&
                                                                    activity?.summary &&
                                                                    JSON.parse(activity?.summary)
                                                                        ?.userDetails &&
                                                                    !JSON.parse(activity?.summary)
                                                                        ?.isAgentJoined &&
                                                                    !JSON.parse(activity.summary)
                                                                        ?.isAgentLeft &&
                                                                    !JSON.parse(activity.summary)
                                                                        ?.isAgentReject && (
                                                                        <div className="gpo-agent-avatar">
                                                                            <ProfileImage
                                                                                profileId={
                                                                                    JSON.parse(
                                                                                        activity?.summary
                                                                                    )?.userDetails
                                                                                        ?.guid
                                                                                }
                                                                            />
                                                                            <span>
                                                                                {
                                                                                    JSON.parse(
                                                                                        activity?.summary
                                                                                    )?.userDetails
                                                                                        ?.displayName
                                                                                }
                                                                                <span className="gpo-time">
                                                                                    {moment(
                                                                                        activity.timestamp
                                                                                    ).fromNow()}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                {activity.from.role == 'user' && (
                                                                    <div className="gpo-user-avatar">
                                                                        <span>
                                                                            You
                                                                            <span className="gpo-time">
                                                                                {moment(
                                                                                    activity.timestamp
                                                                                ).fromNow()}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                )}
                                                                <div className="gpo-message-section__top">
                                                                    <div className="gpo-message-section__top--wrapper">
                                                                        {!!activity.text && (
                                                                            <Attachment
                                                                                content={
                                                                                    activity.text
                                                                                }
                                                                                contentType={
                                                                                    activity.textFormat ===
                                                                                    'markdown'
                                                                                        ? 'text/markdown'
                                                                                        : 'text/plain'
                                                                                }
                                                                                gpoRole={
                                                                                    activity?.from
                                                                                        ?.role
                                                                                }
                                                                                chatID={activity.id}
                                                                                hasHandsOff={
                                                                                    activity?.summary &&
                                                                                    JSON.parse(
                                                                                        activity?.summary
                                                                                    )
                                                                                        ?.showHandOffOptions
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                showTyping={
                                                                                    // !stopTypingUntilNewActivity &&
                                                                                    !activity?.summary
                                                                                        ? !chatState?.stopTyping &&
                                                                                          activity
                                                                                              ?.from
                                                                                              ?.role ==
                                                                                              'bot'
                                                                                        : !chatState?.stopTyping &&
                                                                                          activity?.summary &&
                                                                                          JSON.parse(
                                                                                              activity?.summary
                                                                                          )
                                                                                              ?.showTypingEffect
                                                                                }
                                                                                stopTypingOnTabSwitch={
                                                                                    stopCurrentTyping
                                                                                }
                                                                                isWelcomeMessage={
                                                                                    activity?.summary &&
                                                                                    JSON.parse(
                                                                                        activity?.summary
                                                                                    )
                                                                                        ?.isWelcomeMessage
                                                                                }
                                                                            />
                                                                        )}
                                                                        {!!activity.attachments
                                                                            .length && (
                                                                            <ul>
                                                                                {activity.attachments.map(
                                                                                    (
                                                                                        attachment: any,
                                                                                        index: any
                                                                                    ) => (
                                                                                        <li
                                                                                            key={
                                                                                                index
                                                                                            }>
                                                                                            <Attachment
                                                                                                {...attachment}
                                                                                            />
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        )}

                                                                        <div className="gpo-pdf-action">
                                                                            {activity.from.role ==
                                                                                'bot' &&
                                                                                chatState?.messagesDisplayed.includes(
                                                                                    activity.id
                                                                                ) && (
                                                                                    <>
                                                                                        <div
                                                                                            className="gpo-icon"
                                                                                            onClick={() =>
                                                                                                handleClick(
                                                                                                    activity
                                                                                                )
                                                                                            }>
                                                                                            <>
                                                                                                {getIcon(
                                                                                                    activity
                                                                                                )}
                                                                                                {getPopup(
                                                                                                    activity
                                                                                                )}
                                                                                            </>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                    {activity.from.role == 'bot' &&
                                                                        activity?.summary &&
                                                                        JSON.parse(
                                                                            activity?.summary
                                                                        )?.showFeedbackOptions &&
                                                                        chatState?.messagesDisplayed.includes(
                                                                            activity.id
                                                                        ) && (
                                                                            <div className="user-action">
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleReaction(
                                                                                            activity,
                                                                                            activity
                                                                                                ?.channelData[
                                                                                                'webchat:sequence-id'
                                                                                            ],
                                                                                            getReaction(
                                                                                                activity
                                                                                                    ?.channelData[
                                                                                                    'webchat:sequence-id'
                                                                                                ]
                                                                                            ) == 1
                                                                                                ? -1
                                                                                                : 1
                                                                                        )
                                                                                    }>
                                                                                    {getReaction(
                                                                                        activity
                                                                                            ?.channelData[
                                                                                            'webchat:sequence-id'
                                                                                        ]
                                                                                    ) == 1 ? (
                                                                                        <img
                                                                                            src={
                                                                                                like_fill
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            src={
                                                                                                like
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </button>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleReaction(
                                                                                            activity,
                                                                                            activity
                                                                                                ?.channelData[
                                                                                                'webchat:sequence-id'
                                                                                            ],
                                                                                            getReaction(
                                                                                                activity
                                                                                                    ?.channelData[
                                                                                                    'webchat:sequence-id'
                                                                                                ]
                                                                                            ) == 2
                                                                                                ? -2
                                                                                                : 2
                                                                                        )
                                                                                    }>
                                                                                    {getReaction(
                                                                                        activity
                                                                                            ?.channelData[
                                                                                            'webchat:sequence-id'
                                                                                        ]
                                                                                    ) == 2 ? (
                                                                                        <img
                                                                                            src={
                                                                                                like_fill
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            src={
                                                                                                like
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                </div>

                                                                {chatState?.messagesDisplayed.includes(
                                                                    activity.id
                                                                ) && (
                                                                    <div className="gpo-message-section__chat-footer">
                                                                        {activity?.summary &&
                                                                            JSON.parse(
                                                                                activity?.summary
                                                                            )?.additionalInfo &&
                                                                            JSON.parse(
                                                                                activity?.summary
                                                                            )?.additionalInfo
                                                                                ?.currentResponseCount >
                                                                                1 &&
                                                                            JSON.parse(
                                                                                activity?.summary
                                                                            )?.additionalInfo
                                                                                ?.totalResponseCount !=
                                                                                0 && (
                                                                                <div className="response-count">
                                                                                    <div className="response-count__container">
                                                                                        <span>
                                                                                            {
                                                                                                JSON.parse(
                                                                                                    activity?.summary
                                                                                                )
                                                                                                    ?.additionalInfo
                                                                                                    ?.currentResponseCount
                                                                                            }
                                                                                            /
                                                                                            {
                                                                                                JSON.parse(
                                                                                                    activity?.summary
                                                                                                )
                                                                                                    ?.additionalInfo
                                                                                                    ?.totalResponseCount
                                                                                            }{' '}
                                                                                            responses
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        {activity?.summary &&
                                                                            JSON.parse(
                                                                                activity?.summary
                                                                            )?.showHandOffOptions &&
                                                                            !chatState?.handsOffAcknowledged.some(
                                                                                (item) =>
                                                                                    item.id ===
                                                                                    JSON.parse(
                                                                                        activity?.summary
                                                                                    )
                                                                                        ?.additionalInfo
                                                                                        ?.chatResponseGuid
                                                                            ) && (
                                                                                <div className="agent-confirm">
                                                                                    <div className="agent-confirm__container">
                                                                                        <button
                                                                                            onClick={(
                                                                                                event
                                                                                            ) =>
                                                                                                handleAgentAcknowledgement(
                                                                                                    event,
                                                                                                    'Yes',
                                                                                                    activity,
                                                                                                    AcknowledgmentType.AGENT_TRANSFER
                                                                                                )
                                                                                            }>
                                                                                            Yes
                                                                                        </button>
                                                                                        <button
                                                                                            onClick={(
                                                                                                event
                                                                                            ) =>
                                                                                                handleAgentAcknowledgement(
                                                                                                    event,
                                                                                                    'No',
                                                                                                    activity,
                                                                                                    AcknowledgmentType.AGENT_TRANSFER
                                                                                                )
                                                                                            }>
                                                                                            No
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {list?.length - 1 === index &&
                                                list[list?.length - 1]?.from.role != 'bot' && (
                                                    <div
                                                        className={
                                                            chatHistoryState?.selectedConversation
                                                                ? 'hide gpo-chat-wrapper__list'
                                                                : 'gpo-chat-wrapper__list'
                                                        }>
                                                        <div className={'gpo-bot'}>
                                                            <div className="gpo-chats">
                                                                <div className="gpo-message-section">
                                                                    <div className="gpo-no-data">
                                                                        {agentJoinedGuid &&
                                                                            !handsOffTriggered && (
                                                                                <>
                                                                                    <div className="gpo-agent-avatar">
                                                                                        <ProfileImage
                                                                                            profileId={
                                                                                                agentJoinedGuid
                                                                                            }
                                                                                        />
                                                                                        <span>
                                                                                            {list[
                                                                                                list?.length -
                                                                                                    2
                                                                                            ]
                                                                                                ?.summary
                                                                                                ? JSON.parse(
                                                                                                      list[
                                                                                                          list?.length -
                                                                                                              2
                                                                                                      ]
                                                                                                          ?.summary
                                                                                                  )
                                                                                                      ?.userDetails
                                                                                                      ?.displayName
                                                                                                : ''}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="gpo-loading-data">
                                                                                        <div></div>
                                                                                        <div></div>
                                                                                        <div></div>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        {!agentJoinedGuid &&
                                                                            !handsOffTriggered && (
                                                                                <>
                                                                                    <div className="gpo-bot-avatar">
                                                                                        <img
                                                                                            src={
                                                                                                gpo_logo
                                                                                            }
                                                                                        />
                                                                                        <span>
                                                                                            GPO
                                                                                            ChatBot
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="gpo-loading-data">
                                                                                        <div></div>
                                                                                        <div></div>
                                                                                        <div></div>
                                                                                    </div>
                                                                                </>
                                                                            )}

                                                                        {handsOffTriggered && (
                                                                            <div className="circle-spinner">
                                                                                <div className="circle"></div>
                                                                                <div className="spinner"></div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </React.Fragment>
                                    ))}
                            </>
                        )}
                    </>

                    <div id="messages-end-reference" ref={messagesEndRef} />
                </div>
            </div>
            {chatState.botConnectingStatus != ApiStatus.LOADING &&
                !chatState?.showChatHistoryPanel &&
                !chatState?.searchMessages.result && (
                    <div className="gpo-chat-send">
                        <div
                            className={
                                !chatState?.messageInputEnabled
                                    ? 'gpo-chat-send__field disabled'
                                    : 'gpo-chat-send__field'
                            }>
                            <form onSubmit={sendBoxValue ? handleSubmit : undefined}>
                                <input
                                    autoFocus={true}
                                    disabled={!chatState?.messageInputEnabled}
                                    onChange={handleChange}
                                    type="text"
                                    spellCheck="true"
                                    value={sendBoxValue}
                                    placeholder="Enter your message"
                                />
                                <button
                                    type="submit"
                                    disabled={
                                        !chatState?.messageInputEnabled || sendBoxValue == ''
                                    }>
                                    <svg viewBox="0 0 21 18" fill="none">
                                        <path
                                            d="M0.00999999 18L21 9L0.00999999 0L0 7L15 9L0 11L0.00999999 18Z"
                                            fill="white"
                                        />
                                    </svg>
                                </button>
                            </form>
                        </div>
                    </div>
                )}
        </div>
    );
};

export default memo(Chat);

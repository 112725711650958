import { close_grey, gpo_logo, open_green, profile_logout } from "assets/images";
import "./Messages.scss";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { chatHistoryActions } from "modules/gpo/redux/chatHistory";
import { ChatHistoryDetail, ChatHistoryModel, ChatUserDetails, UserChatHistory } from "common/models/chat/chat-history.model";
import { useEffect, useRef, useState } from "react";
import React from "react";
import moment from "moment";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import HighlightText from "../HighlightText/HighlightText";
import { ChatMessageType } from "common/enums/chat-message-types.enum";
import { chatActions } from "modules/gpo/redux/chat";
import { ApiStatus } from "common/enums";
import DotLoader from "common/components/base/DotLoader/DotLoader";
import { ChatConversationStatus } from "common/enums/chat-conversation-status.enum";
import { hooks } from "botframework-webchat-component";

const Messages = () => {
  const chatHistoryState = useAppSelector((state: RootState) => state.chatHistory);
  const chatState = useAppSelector((state: RootState) => state.chat);
  const userState = useAppSelector((state: RootState) => state.user);
  const chatHistoryDispatch = useAppDispatch(); 
  const searchRef:any = useRef(); 
  const [searchInput, setSearchInput] = useState<string>("");
  const [chatHistoryMessages, setChatHistoryMessages] = useState<ChatHistoryDetail[]>([]);
  const [selectedSearchResult, setSelectedSearchResult] = useState<ChatHistoryDetail>();
  const [currentSelectedSearchMessage,setCurrentSelectedSearchMessage] = useState<ChatHistoryDetail>();
  const {
    useActivities
  } = hooks;
  
  const [activities] = useActivities();
  
  useEffect(()=>{
    if(chatState?.searchMessages.result){
      const searchInputValue:any = document?.getElementById("history-search");
      if(searchInputValue){
       setSearchInput(searchInputValue?.value);
      }
      const searchedActivities = activities?.filter(activity=>activity?.type=="message" && activity?.text?.toLowerCase()?.includes(searchInputValue?.value?.toLowerCase()))
       const searchedActivityDetails:any=[];
       searchedActivities?.forEach((activity:any)=>{
          const userDetail:any={
            guid: activity?.from?.role == "bot"?activity?.from?.id:userState.userDetails?.guid,
            displayName: activity?.from?.role == "bot"?activity?.from?.name:userState.userDetails?.displayName,
            emailId: activity?.from?.role == "bot"?"Bot@gds.ey.com":userState.userDetails?.emailId,
            createdDate: "",
            userIdentity: undefined,
            roles: activity?.from?.role == "bot"?undefined:userState.userDetails?.userRoles
          };
          const activityDetails:any={
            messageGuid: activity?.id?activity?.id:"",
            messageTypeId: activity.from.role == "bot"?ChatMessageType?.BOTMESSAGE:ChatMessageType?.CLIENTMESSAGE,
            messageText: activity?.text,
            messageCreatedDate: activity?.timestamp,
            user: userDetail,
            conversationGuid: "",
            conversationStatusId: ChatConversationStatus?.ACTIVE,
            isMessageLiked: false,
            additionalInfo: undefined
          }

          searchedActivityDetails?.push(activityDetails);
       })
       const searchArray:any = searchedActivityDetails.concat(chatState?.searchMessages.result);
       if(chatState?.searchMessages.status === ApiStatus.SUCCESS){
       setChatHistoryMessages(searchArray);
       }
       const wrapper = document.getElementsByClassName("gpo-custom-chat-container")[0];
       if(wrapper){
        wrapper?.classList?.add("message-list");
       }
       if(chatState?.searchMessages.result?.length){
        selectSearchResult(chatState?.searchMessages[0]);
       }
       else{
        setSelectedSearchResult(undefined);
       }
      } 
      else{
        setChatHistoryMessages([]);
      }
  },[chatState?.searchMessages])

  useEffect(()=>{
    if(chatHistoryState?.selectedConversation){
      const delaySelectChatFn = setTimeout(() => {
        const chatHistoryElement = document.querySelector(".gpo-chat-history");
        const selectedChat = currentSelectedSearchMessage?.conversationStatusId==ChatConversationStatus.CLOSED ? chatHistoryElement?.querySelector("#history-"+currentSelectedSearchMessage?.messageGuid): document.getElementById('live-chat_'+currentSelectedSearchMessage?.messageGuid);
        if(selectedChat){
          selectSearchResult(currentSelectedSearchMessage);
          clearTimeout(delaySelectChatFn);
        }
      }, 100)
    }

  },[chatHistoryState?.selectedConversation])

  const handleMessageClick = (message: ChatHistoryDetail) =>{
    //selectSearchResult(message);
    if(message?.conversationStatusId == ChatConversationStatus?.CLOSED){
    setCurrentSelectedSearchMessage(message);
    chatHistoryDispatch(chatHistoryActions?.getSelectedChatHistoryDetails(message.conversationGuid));
    }
    else{
      chatHistoryDispatch(chatHistoryActions?.clearSelectedConversation());     
      const delaySelectChatFn = setTimeout(() => {
        const chatHistoryElement = document.querySelector(".gpo-chat-history");
        const selectedChat = document.getElementById('live-chat_'+message?.messageGuid);
        if(selectedChat){
          selectSearchResult(message);
          clearTimeout(delaySelectChatFn);
        }
      }, 100)
    }
    //chatHistoryDispatch(chatActions?.updateStopTyping(true));
  }

  const selectSearchResult= (searchItem:ChatHistoryDetail|undefined) => {
    if(searchItem){
    setSelectedSearchResult(searchItem);
    
    const chatHistoryElement = document.querySelector(".gpo-chat-history");
    const selectedSearchChat = document?.querySelector(".selectedSearch");
    if(selectedSearchChat){
      selectedSearchChat?.classList?.remove("selectedSearch");
    }
    const selectedChat = searchItem?.conversationStatusId==ChatConversationStatus.CLOSED ? chatHistoryElement?.querySelector("#history-"+searchItem?.messageGuid): document.getElementById('live-chat_'+searchItem?.messageGuid);
    
    if(selectedChat){
      if(searchItem?.conversationStatusId==ChatConversationStatus.CLOSED){
      const textWrapper = selectedChat?.querySelector(".message-section__wrapper")?selectedChat?.querySelector(".message-section__wrapper"):selectedChat?.querySelector("p.displayText");
      textWrapper?.classList?.add("selectedSearch");
      //selectedChat?.querySelector(".message-section__wrapper")?selectedChat?.querySelector(".message-section__wrapper")?.classList?.add("selectedSearch"):selectedChat?.querySelector("p.displayText")?.classList?.add("selectedSearch");
      textWrapper?.scrollIntoView({behavior:"smooth", block: "end", inline:"nearest"});
      }
      else{
        selectedChat?.parentElement?.classList?.add("selectedSearch");
        selectedChat?.parentElement?.scrollIntoView({behavior:"smooth", block: "end", inline:"nearest"});
      }
    }
   }
  }
  const scrollToBottom = () =>{
    const messagesWrapper = document.getElementsByClassName('gpo-custom-chat-messages')[0];
    if(messagesWrapper){
      messagesWrapper.scroll({ top: messagesWrapper.scrollHeight,behavior:'smooth'});
    }
  }
  const clearSearch = () =>{
    setChatHistoryMessages([]);
    const searchElem:any = document.getElementById('history-search');
    if(searchElem){
      searchElem.value = "";
    }
    
   // const chatHistoryElement = document.querySelector(".gpo-chat-history");
    const selectedSearchChat = document?.querySelector(".selectedSearch");
    if(selectedSearchChat){
      selectedSearchChat?.classList?.remove("selectedSearch");
    }
    chatHistoryDispatch(chatActions?.resetSearchChat());   
    const wrapper = document.getElementsByClassName("gpo-custom-chat-container")[0];
    if(wrapper){
      wrapper?.classList?.remove("message-list");
    }
    scrollToBottom();
    //chatHistoryDispatch(chatHistoryActions?.updateSearchValue(searchInput));
    //chatHistoryDispatch(chatHistoryActions?.updateSearchTriggered(false));
    chatHistoryDispatch(chatActions.resetSearchChat());
    chatHistoryDispatch(chatHistoryActions.clearSelectedConversation());
  }

  return chatState?.searchMessages.result ?(
    <div className="messages">
      <div className="messages__container">
        <header>
          <span>Messages</span>
          <img src={close_grey} className="close" 
           onClick={clearSearch}
           />
        </header>
        <div className="list-wrapper">
         {chatHistoryMessages && chatHistoryMessages?.map((message:ChatHistoryDetail, i) => 
          <React.Fragment key={message?.messageGuid}>
          <div className={selectedSearchResult?.messageGuid==message?.messageGuid?"list active":"list"}
            onClick={() => {handleMessageClick(message) }}
           >
            {(message?.messageTypeId == ChatMessageType?.AGENTMESSAGE || message?.messageTypeId == ChatMessageType?.CLIENTMESSAGE) &&(
              <ProfileImage 
              profileId={message?.user?.guid}
              />
            )}
             {message?.messageTypeId == ChatMessageType?.BOTMESSAGE &&(
             <img src={gpo_logo} alt="" />
             )}
            <div className="details">
              <div className="top">
                <h6>{message?.user?.displayName}</h6>
                <span>{moment.utc(message?.messageCreatedDate)?.local().format("DD-MM")} {moment.utc(message?.messageCreatedDate)?.local().format("HH:mm")}</span>
              </div>
              <HighlightText 
              showTextOnHover={true}
              displayText={message?.messageText} searchText={searchInput}/>
            </div>
          </div>
         </React.Fragment>
         )}
          {chatState?.searchMessages.status == ApiStatus.LOADING &&
          <p>Loading...</p>
          }

         {chatHistoryMessages 
         && !chatHistoryMessages?.length 
         && chatState?.searchMessages.status === ApiStatus.SUCCESS &&
         (
          <p className="no-data">No data available</p>
         )}
        </div>
      </div>
    </div>
  ):<></>;
};

export default Messages;

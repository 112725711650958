import { ENV_CONFIG } from "./env.config";

export const HTTP_METHOD = {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE'
};

export const GRAPH_SCOPES = {
    OPENID: 'openid',
    PROFILE: 'profile',
    USER_READ: 'User.Read'
};

export const WORKER_CONFIG = {
    VALIDATION_WORKER: './worker/upload-file-validator.js'
};

export const POLLING_CONFIG = {
    RATIONALIZATION_INGEST_MAPPING_PROCESS: 10000,
    RATIONALIZATION_CONFIGURE_PROCESS: 10000,
    EXPORT_RATIONALIZATION_REPORT_STATUS_PROCESS: 10000,
    EXPORT_QUALITY_SCORING_REPORT_STATUS_PROCESS: 10000,
    PROFILE_RANKING_CONFIGURE_PROCESS: 10000,
    PROFILE_RANKING_MAPPING_PROCESS: 10000,
    RISK_SCORING_CONFIGURE_PROCESS: 10000,
    DASHBOARD_CONFIGURE_PROCESS: 10000,
    POWERBI_REPORT_STATUS_PROCESS: 10000
};

export const IDLE_TIME_IN_MIN = ENV_CONFIG.APP_IDLE_TIME_MIN || 10;
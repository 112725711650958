export const APP_CONTROLLER = {
    BOTFRAMEWORK: 'BotFramework',
    CHAT:'Chat',
    USER:'User',
    DOCUMENTS:'Documents',
    BOOKMARKS:'bookmarks',
    MESSAGES: 'Messages',
};

export const METHOD = {
    TOKENS: 'tokens',
    FEEDBACK:'feedback',
    SESSION:'session',
    LOGOUT:'logout',
    HISTORY:'history',
    REQUEST_ACCESS:'request-access',
    CLOSED_CONVERSATIONS:'ClosedConversations',
    MESSAGE:'message',
    BOOKMARKS:'bookmarks',
    SEARCH:'search',
    MANAGED_DOCUMENTS:'documents/managed',
    DOWNLOAD:'download'
};
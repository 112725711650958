import React, { Fragment, useState } from 'react';
import './DocumentListModal.scss';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'common/components';
import { IReferenceDocument } from 'common/models/chat/reference-document.model';
import { sort_down, sort_up } from 'assets/media';
import { SortColumnParams } from 'modules/gpo/models/document-list.model';
import { DocumentListColumns } from 'modules/gpo/enums/document-list.enum';

type DocumentListModalProps = {
    documents: IReferenceDocument[];
    onClose: () => void;
    onRowClick: (documentGuid: IReferenceDocument) => void;
};

const DocumentListModal = ({ documents, onRowClick, onClose }: DocumentListModalProps) => {
    const [sortColumn, setSortColumn] = useState<SortColumnParams>({
        colId: '',
        order: ''
    });

    const [documentData, setDocumentData] = useState(documents);

    const handleSortClick = (col: DocumentListColumns) => {
        const nextOrder =
            sortColumn.order === '' ? 'asc' : sortColumn.order === 'asc' ? 'desc' : '';

        setSortColumn({
            colId: col,
            order: nextOrder
        });

        const data = [...documents];

        switch (col) {
            case DocumentListColumns.DocumentName:
                if (nextOrder === 'asc') {
                    const sorted = data.sort((a, b) =>
                        a.documentName.localeCompare(b.documentName)
                    );
                    setDocumentData([...sorted]);
                } else if (nextOrder === 'desc') {
                    setDocumentData([
                        ...data.sort((a, b) => b.documentName.localeCompare(a.documentName))
                    ]);
                } else {
                    setDocumentData([...data]);
                }
                break;

            case DocumentListColumns.Pages:
                if (nextOrder === 'asc') {
                    setDocumentData([...data.sort((a, b) => a.pages.length - b.pages.length)]);
                } else if (nextOrder === 'desc') {
                    setDocumentData([...data.sort((a, b) => b.pages.length - a.pages.length)]);
                } else {
                    setDocumentData([...data]);
                }
                break;
        }
    };

    const getSortIcons = (col: DocumentListColumns) => {
        const defaultSortIcon = (
            <>
                <img src={sort_up} alt="sort-asc" />
                <img src={sort_down} alt="sort-desc" />
            </>
        );
        
        if (sortColumn.colId !== col) {
            return defaultSortIcon;
        }

        switch (sortColumn.order) {
            case 'asc':
                return <img src={sort_up} alt="sort-asc" />;
            case 'desc':
                return <img src={sort_down} alt="sort-desc" />;
            default:
                return defaultSortIcon;
        }
    };

    return (
        <Modal>
            <ModalHeader onClose={onClose}>Source Document List</ModalHeader>
            <ModalBody className="document-list-modal">
                <div className="document-list-modal__grid-container">
                    <div
                        className="document-list-modal__column-header"
                        onClick={() => handleSortClick(DocumentListColumns.DocumentName)}>
                        <div>SOURCE DOCUMENTS</div>
                        <div className="document-list-modal__column-header__sort">
                            {getSortIcons(DocumentListColumns.DocumentName)}
                        </div>
                    </div>
                    <div
                        className="document-list-modal__column-header"
                        onClick={() => handleSortClick(DocumentListColumns.Status)}>
                        <div>STATUS</div>
                        <div className="document-list-modal__column-header__sort">
                            {getSortIcons(DocumentListColumns.Status)}
                        </div>
                    </div>
                    <div
                        className="document-list-modal__column-header"
                        onClick={() => handleSortClick(DocumentListColumns.Pages)}>
                        <div>PAGES</div>
                        <div className="document-list-modal__column-header__sort">
                            {getSortIcons(DocumentListColumns.Pages)}
                        </div>
                    </div>
                    {documentData.map((doc) => (
                        <Fragment key={doc.documentGuid}>
                            <div
                                className="document-list-modal__row"
                                onClick={() => onRowClick(doc)}>
                                {doc.documentName}&nbsp; <span className="document-list-modal__version">{doc.version?.toUpperCase()}</span>
                            </div>
                            <div
                                className={`document-list-modal__row document-list-modal__status ${doc.version ? 'document-list-modal__status--active' : 'document-list-modal__status--inactive'}`}
                                onClick={() => onRowClick(doc)}>
                                {doc.active ? "Active": "Archived"}
                            </div>
                            <div
                                className="document-list-modal__row"
                                onClick={() => onRowClick(doc)}>
                                {doc.pages.map((item) => item.pageNumber).join()}
                            </div>
                        </Fragment>
                    ))}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button variant="primary-alt" onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DocumentListModal;

import { FC, useEffect, useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { close_btn, maximize, reset } from 'assets/images';
import {
    close_btn_black,
    up_arrow_disabled,
    down_arrow,
    chevronRight,
    up_arrow,
    down_arrow_disabled
} from 'assets/media/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import './PDFViewer.scss';
import React from 'react';
import Loader from 'common/components/base/Loader/Loader';
import ReactDOM from 'react-dom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { IDocumentDetails, IDocumentPage, IReferenceDocument } from 'common/models/chat/reference-document.model';
import { RootState, useAppDispatch, useAppSelector } from 'common/redux/core/root.reducer';
import { chatActions } from 'modules/gpo/redux/chat/chat.slice';
import DocumentListModal from '../DocumentListModal/DocumentListModal';

type PDFViewerProps = {
    onViewDoc: (pageNumbers: IDocumentPage[], documentGuid: string, activityId: string,documentName:string) => void;
};

const PDFViewer: FC<PDFViewerProps> = ({ onViewDoc }: PDFViewerProps) => {
    const chatStateDispatch = useAppDispatch();
    const [fileStatus, setFileStatus] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [shown, setShown] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [pageNumbers, setPageNumbers] = useState<number[]>([]);
    const [documentName, setDocumentName] = useState('');
    const [documentGuid, setDocumentGuid] = useState('');
    const [currentDocIndex, setCurrentDocIndex] = useState(0);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);

    const chatState = useAppSelector((state: RootState) => state.chat);
    const docsData = chatState.documentsData.documents;

    const shouldNextDocDisabled = currentDocIndex === docsData.length - 1;
    const shouldPrevDocDisabled = currentDocIndex === 0;
    const shouldPrevPageDisabled = currentPageIndex === 0;
    const shouldNextPageDisabled = currentPageIndex === pageNumbers.length - 1;

    useEffect(() => {
        if (chatState?.isDocumentOpen) {
            setFileLoading(true);
            const wrapperDiv = document.getElementById('gpo-custom-chat-wrapper');
            if (wrapperDiv) {
                wrapperDiv.classList.add('gpo-multiple-wrapper');
                const heightDiv = document.getElementById('history-' + chatState?.chatScrollHeight)
                    ? document.getElementById('history-' + chatState?.chatScrollHeight)
                    : document.getElementById('live-chat_' + chatState?.chatScrollHeight);
                const messagesWrapper = document.getElementsByClassName(
                    'gpo-custom-chat-messages'
                )[0];
                if (messagesWrapper && heightDiv) {
                    heightDiv.scrollIntoView({ block: 'end', inline: 'end' });
                }
            }
        } else{
          handleCloseDoc();
        }
    }, [chatState?.isDocumentOpen]);

    useEffect(() => {
        if (
            chatState?.viewDocURL &&
            chatState?.viewDocName &&
            chatState?.viewDocPageNumbers &&
            chatState?.viewDocPageNumbers?.length
        ) {
            const pages = chatState?.viewDocPageNumbers.map((item) => item.pageNumber);
            const sortedPages = pages.sort((a, b) => a - b);
            setPdfUrl(chatState?.viewDocURL);
            setPageNumbers(sortedPages);
            setDocumentName(chatState?.viewDocName);
        }
    }, [chatState?.viewDocURL, chatState?.viewDocName,chatState?.viewDocPageNumbers]);

    useEffect(() => {
        if (chatState?.viewDocGuid) {
            setDocumentGuid(chatState?.viewDocGuid);
            getDocumentPDFURL();
        }
    }, [chatState?.viewDocGuid]);

    // useEffect(() => {
    //     if (chatState?.pdfDocumentDetails) {
    //         setFileLoading(true);
    //         const documentDetails: IDocumentDetails = chatState?.pdfDocumentDetails;
    //         chatStateDispatch(chatActions.updateChatDocUrlState(documentDetails?.sasUrl));
    //         setDocumentName(documentDetails?.documentName);
    //     }
    // }, [chatState?.pdfDocumentDetails]);
    useEffect(()=>{
       if(chatState?.pdfFileState){
        setFileLoading(true);
        const file = new Blob([chatState?.pdfFileState], { type:"application/pdf" });
        const url = URL.createObjectURL(file);
        chatStateDispatch(chatActions.updateChatDocUrlState(url));
        chatStateDispatch(chatActions?.resetPDFFile());
        
       }
    },[chatState?.pdfFileState])

    const getDocumentPDFURL = () => {
        //chatStateDispatch(chatActions.getPDFDetails(chatState?.viewDocGuid));
        chatStateDispatch(chatActions.getPDFFile(chatState?.viewDocGuid));
    };

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setFileLoading(false);
        setFileStatus(true);
    };

    const handleCloseDoc = () => {
        setFileStatus(false);
        chatStateDispatch(chatActions?.updateDocOpenState(false));
        chatStateDispatch(chatActions?.updateChatDocGuidState(''));

        //clear data on viewer close
        chatStateDispatch(chatActions?.clearPdfDocsData());
        chatStateDispatch(chatActions.updateChatDocUrlState(''));
        setDocumentName('');
        setDocumentGuid('');
        setCurrentDocIndex(0);
        setCurrentPageIndex(0);
        setFileLoading(false);
        setPdfUrl('');

        const wrapperDiv = document.getElementById('gpo-custom-chat-wrapper');
        if (wrapperDiv) {
            wrapperDiv.classList.remove('gpo-multiple-wrapper');
        }
        const messagesWrapper = document.getElementsByClassName('gpo-custom-chat-messages')[0];
        const heightDiv = document.getElementById('history-' + chatState?.chatScrollHeight)
            ? document.getElementById('history-' + chatState?.chatScrollHeight)
            : document.getElementById('live-chat_' + chatState?.chatScrollHeight);
        if (messagesWrapper && heightDiv) {
            heightDiv.scrollIntoView({ block: 'end', inline: 'end' });
        }
        // if(messagesWrapper){
        //   messagesWrapper.scroll({ top: messagesWrapper.scrollHeight});
        // }
    };

    const modalBody = () => (
        <div className="fullscreen-modal">
            <header>
                <span>{documentName}</span>
                <img src={close_btn} onClick={() => setShown(false)} />
            </header>
            <div className="fullscreen-modal__body">
                <Worker workerUrl={'/pdf.worker_3_5_127.js'}>
                    <Viewer fileUrl={pdfUrl} initialPage={pageNumbers[currentPageIndex] - 1 || 0} />
                </Worker>
            </div>
        </div>
    );

    const handleViewDoc = (
        pageNumbers: IDocumentPage[],
        documentGuid: string,
        activityId: string,
        documentName:string
    ) => {
        onViewDoc(pageNumbers, documentGuid, activityId,documentName);
    };

    const handleNextDocument = () => {
        setFileLoading(true);
        const activityId = chatState.documentsData.activityId;

        if (currentDocIndex + 1 < docsData.length) {
            setCurrentDocIndex(currentDocIndex + 1);
            setCurrentPageIndex(0);
            handleViewDoc(
                docsData[currentDocIndex + 1]?.pages,
                docsData[currentDocIndex + 1]?.documentGuid,
                activityId,
                docsData[currentDocIndex + 1]?.documentName,
            )
        }
    };

    const handlePreviousDocument = () => {
        setFileLoading(true);
        const activityId = chatState.documentsData.activityId;

        if (currentDocIndex - 1 >= 0) {
            setCurrentDocIndex(currentDocIndex - 1);
            setCurrentPageIndex(0);
            handleViewDoc(
                docsData[currentDocIndex - 1]?.pages,
                docsData[currentDocIndex - 1]?.documentGuid,
                activityId,
                docsData[currentDocIndex - 1]?.documentName,
            );
        }
    };

    const goToPreviousPage = () => {
        if (currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1);
        }
    };

    const goToNextPage = () => {
        if (currentPageIndex < pageNumbers.length - 1) {
            setCurrentPageIndex(currentPageIndex + 1);
        }
    };

    const handleDocumentRowClick = (document: IReferenceDocument) => {
      const activityId = chatState.documentsData.activityId;
      setShowDocumentsModal(false);
      setCurrentPageIndex(0);
      onViewDoc(document.pages, document.documentGuid, activityId,document.documentName);
  };

    return (
        <React.Fragment>
            {chatState.isDocumentOpen && (
                <div className="gpo-pdf-viewer">
                    <div className="doc">
                        <div className="gpo-pdf-viewer__header">
                          <div className="gpo-pdf-viewer__header__close_btn" onClick={handleCloseDoc}>
                            <img src={close_btn_black} />
                          </div>
                            <div className="gpo-pdf-viewer__header-row">
                                <button
                                    className={`gpo-pdf-viewer__header-row__prev-btn ${
                                        shouldPrevDocDisabled ? 'gpo-pdf-viewer__header-row__prev-btn--disabled' : ''
                                    }`}
                                    disabled={shouldPrevDocDisabled}
                                    onClick={handlePreviousDocument}
                                    title={shouldPrevDocDisabled ? '' : 'Previous document'}>
                                    <img src={chevronRight} />
                                </button>
                                {/* <img className={`pdf-viewer__header-row--left-icon`} src={chevronRight} /> */}
                                <div className="gpo-pdf-viewer__header--see-all">
                                 <p>
                                    Document {currentDocIndex + 1} of{' '}
                                    {chatState.documentsData?.documents?.length || 1}
                                 </p>
                                    
                                    {docsData.length > 1 && (
                                    <a onClick={() => setShowDocumentsModal(true)}>See all</a>
                                    )}
                                </div>
                                <button
                                    className={`gpo-pdf-viewer__header-row__next-btn ${
                                        shouldNextDocDisabled ? 'gpo-pdf-viewer__header-row__next-btn--disabled' : ''
                                    }`}
                                    disabled={shouldNextDocDisabled}
                                    onClick={handleNextDocument}
                                    title={shouldNextDocDisabled ? '' : 'Next document'}>
                                    <img src={chevronRight} />
                                </button>
                            </div>
                            <div className="gpo-pdf-viewer__header-row">
                                <p className="gpo-pdf-viewer__header-row--title" title={documentName}>
                                    {documentName}
                                </p>

                                <div className="gpo-pdf-viewer__header__controls">
                                    <div>
                                        <img
                                            src={
                                                shouldPrevPageDisabled
                                                    ? up_arrow_disabled
                                                    : up_arrow
                                            }
                                            onClick={goToPreviousPage}
                                        />
                                        <img
                                            src={
                                                shouldNextPageDisabled
                                                    ? down_arrow_disabled
                                                    : down_arrow
                                            }
                                            onClick={goToNextPage}
                                        />
                                    </div>
                                    <p>
                                        {currentPageIndex + 1} of {pageNumbers.length || 1}
                                    </p>
                                    <p
                                        className="gpo-pdf-viewer__header--visible-page"
                                        title={`( Pg- ${pageNumbers.join(', ')} )`}>
                                        ( Pg- {pageNumbers.join(', ')} )
                                    </p>
                                    <div
                                        className={`gpo-pdf-viewer__header__maximize ${
                                            !fileStatus ? 'gpo-pdf-viewer__header__maximize--disabled' : ''
                                        }`}>
                                        <img src={maximize} onClick={() => setShown(true)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {fileLoading && <Loader />}
                        <Document
                            file={pdfUrl}
                            options={{ workerSrc: '/pdf.worker.js' }}
                            onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumbers[currentPageIndex]} />
                        </Document>
                    </div>
                </div>
            )}
            {shown && ReactDOM.createPortal(modalBody(), document.body)}

            {showDocumentsModal && (
                <DocumentListModal
                    documents={docsData}
                    onRowClick={handleDocumentRowClick}
                    onClose={() => setShowDocumentsModal(false)}
                />
            )}
        </React.Fragment>
    );
};

export default memo(PDFViewer);

import { SilentRequest } from '@azure/msal-browser';
import { msalInstance } from 'common/config/auth.config';
import { HTTP_HEADER_KEY } from 'common/enums';
import { ApiConfig } from 'common/models';
import { BaseHTTPClient } from './base-http-client.service';
import { protectedResources } from '../config/auth.config'
import { ENV_CONFIG } from 'common/config/env.config';

export abstract class HttpClient extends BaseHTTPClient {
    protected static async invokeApi<T>(apiConfig: ApiConfig<any>) {
        let result;
        const request: SilentRequest = {
            scopes: protectedResources.api.scopes
        };
        const acquireTokenResult = await msalInstance.acquireTokenSilent(request);
        const header = {
            [HTTP_HEADER_KEY.Authorization]: `Bearer ${acquireTokenResult.accessToken}`,
            ...apiConfig.header
        };
        switch (apiConfig.method) {
            case 'GET': {
                const url =
                    protectedResources.api.endpoint +
                    apiConfig.url +
                    (apiConfig.queryString ? `?${apiConfig.queryString}` : '');
                result = await this.get<T>(url, header);
                break;
            }
            case 'POST':
                result = await this.post<T>(
                    protectedResources.api.endpoint + apiConfig.url,
                    apiConfig.data,
                    header,
                    apiConfig.responseType
                );
                break;
            case 'PUT':
                result = await this.put<T>(
                    protectedResources.api.endpoint + apiConfig.url,
                    apiConfig.data,
                    header
                );
                break;
            case 'DELETE': {
                result = await this.delete<T>(
                    protectedResources.api.endpoint + apiConfig.url,
                    apiConfig.data,
                    header
                );
                break;
            }
        }
        return result;
    }
}

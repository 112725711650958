import React from "react";
import "./DotLoader.scss";
type loaderProps = { fullScreen?: boolean };

const DotLoader = ({ fullScreen }: loaderProps) => {
  return (
    // <div className={["loader-wrapper" , fullScreen ? "fullscreen" : ""].join(' ')}>
    //   <div className="loader-wrapper__container">
    //   <div className="loader"></div>
    //     <span>Loading...</span>
    //   </div>
    // </div>
    <div
      className={["dot-loader-wrapper", fullScreen ? "fullscreen" : ""].join(" ")}
    >
      <div className="dot-loader-wrapper__container">
      <div className="tbl-loader">
        <span className="dot dot_1"></span>
        <span className="dot dot_2"></span>
        <span className="dot dot_3"></span>
        <span className="dot dot_4"></span>
        <h4>Loading...</h4>
    </div>
      </div>
    </div>
  );
};
export default DotLoader;
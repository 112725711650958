import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChatFeedbackRequest } from 'common/models/chat/chat-feedback-request.model';
import { ChatHistoryDetail, SearchChatRequest, UserChatHistory } from 'common/models/chat/chat-history.model';
import { IDocumentDetails, IDocumentPage, IPdfDocsData } from 'common/models/chat/reference-document.model';
import { initialUserState } from 'common/redux/store/user/user.state';
import { initalChatState } from './chat.state';
import { ApiStatus } from 'common/enums';


const chatSlice = createSlice({
    name: 'chat',
    initialState: initalChatState,
    reducers: {
        sendUserFeedback: (state,{ payload }: PayloadAction<IChatFeedbackRequest>) => {
        },
        sendUserFeedbackSuccess: (state, { payload }: PayloadAction<any>) => {
             state.userFeedbackState = payload;
        },
        sendUserFeedbackFailed: (state, { payload }: PayloadAction<any>) => {
            state.userFeedbackState = initalChatState.userFeedbackState;
            console.log("Errors",payload);
        },
        getPDFDetails: (state,{ payload }: PayloadAction<string>) => {
            
        },
        getPDFDetailsSuccess: (state, { payload }: PayloadAction<IDocumentDetails>) => {
            state.pdfDocumentDetails = payload;
        },
        getPDFDetailsFailed: (state, { payload }: PayloadAction<any>) => {
            state.pdfDocumentDetails = initalChatState.pdfDocumentDetails
            console.log("Errors",payload);
        },
        updateChatHandsOffState: (state,action: PayloadAction<{id:string,message:string}>) => {
            state.handsOffAcknowledged.push(action.payload)
        },
        updateChatDocUrlState: (state,action: PayloadAction<string>) => {
            state.viewDocURL = action.payload
        },
        updateChatDocGuidState: (state,action: PayloadAction<string>) => {
            state.viewDocGuid = action.payload
        },
        updateChatDocNameState: (state,action: PayloadAction<string>) => {
            state.viewDocName = action.payload
        },
        updateChatDocPageNumState: (state,action: PayloadAction<IDocumentPage[]>) => {
            state.viewDocPageNumbers = action.payload
        },
        updateDocOpenState: (state,action: PayloadAction<boolean>) => {
            state.isDocumentOpen = action.payload
        },
        updateChatScrollHeight:(state,action:PayloadAction<any>)=>{
            state.chatScrollHeight = action.payload
        },
        updateAgentLeavingAcknowledgedState: (state,action: PayloadAction<{id:string,message:string}>) => {
            state.agentLeavingAcknowledged.push(action.payload)
        },
        updateMessageInputState: (state,action: PayloadAction<boolean>) => {
            state.messageInputEnabled = action.payload
        },
        updateMessageDisplayed: (state,action: PayloadAction<string>) => {
            state.messagesDisplayed.push(action.payload)
        },
        updateChatResults : (state,action: PayloadAction<UserChatHistory>) =>{
            state.chatSearchResults.push(action?.payload)
        },
        updateChatSearchMessages : (state,action: PayloadAction<ChatHistoryDetail[]|undefined>) =>{
            state.searchMessages.result = action?.payload
        },
        updateChatScrolled : (state,action: PayloadAction<boolean>) =>{
            state.chatScrolled = action?.payload
        },
        updateShowChatHistoryPanel : (state,action: PayloadAction<boolean>) =>{
            state.showChatHistoryPanel = action?.payload
        },
        updateStopTyping : (state,action: PayloadAction<boolean>) =>{
            state.stopTyping = action?.payload
        },
        updateActivitiesCopy : (state,action: PayloadAction<Array<any>>) =>{
            state.activitiesCopy = action?.payload
        },
        updateChatSelected : (state,action: PayloadAction<string>) =>{
            state.chatSelected = action?.payload;
        },
        setPdfDocsData: (state, action: PayloadAction<IPdfDocsData>) => {
            state.documentsData.documents = action?.payload?.documents;
            state.documentsData.activityId = action?.payload?.activityId;
        },
        clearPdfDocsData: (state) => {
            state.documentsData.documents = [];
            state.documentsData.activityId = '';
        },
        searchChatBegin : (state,action: PayloadAction<SearchChatRequest>) =>{
            state.searchMessages.result = [];
            state.searchMessages.status = ApiStatus.LOADING;
        },
        searchChatSuccess : (state,{payload}: PayloadAction<ChatHistoryDetail[]>) =>{
            state.searchMessages.result = payload;
            state.searchMessages.status = ApiStatus.SUCCESS;
        },
        searchChatError : (state) =>{
            state.searchMessages.status = ApiStatus.ERROR;
        },
        resetSearchChat : (state) =>{
            state.searchMessages.status = ApiStatus.NONE;
            state.searchMessages.result = undefined;
        },
        updateBotConnectingStatus: (state, { payload }: PayloadAction<ApiStatus>) =>{
            state.botConnectingStatus = payload;
        },
        getPDFFile :(state,{payload}:PayloadAction<string>) =>{
      
        },
        getPDFFileSuccess: (
          state,
          { payload }: PayloadAction<any>
        ) => {
          state.pdfFileState = payload;
        },
        getPDFFileError: (state) => {
          state.pdfFileState = initalChatState?.pdfFileState;
        },
        resetPDFFile: (state) => {
            state.pdfFileState = initalChatState?.pdfFileState;
        },
    }
});

export const chatActions = chatSlice.actions;

export default chatSlice.reducer;

import React from 'react';
import './ModalFooter.scss';

type ModalFooterProps = {
    children: React.ReactNode;
}
const ModalFooter = ({ children }: ModalFooterProps) => {
    return (
        <div className='modal-footer'>
            {children}
        </div>
    );
};

export default ModalFooter;
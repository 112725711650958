import React from 'react';
import './Modal.scss';

type ModalProps = {
    children: React.ReactNode;
    size?: 'sm' | 'md' | 'lg' | 'xl';
    className?: string;
}
const Modal = ({ size = 'md',className = '', children }: ModalProps) => {
    const getSizeClass = () => {
        switch (size) {
            case 'sm':
             return 'modal-size-sm';
             case 'md':
             return 'modal-size-md';
            case 'lg':
             return 'modal-size-lg';
             case 'xl':
                return 'modal-size-xl';
            default:
                break;
        }
    }
    return (
        <div className='modal-root'>
            <div className={`modal-overlay ${className}`}>
                <div className={`modal modal-content ${getSizeClass()}`}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
import React from 'react';
import './IconButton.scss';

type IconButtonProps = {
    className?: string;
    onClick?: () => void;
    size?: 'small' | 'medium' | 'large';
    iconSrc: string;
};

const IconButton = ({ className, size = 'small', iconSrc, onClick }: IconButtonProps) => {
    const getSizeClass = () => {
        switch (size) {
            case 'small':
                return 'icon-button-small';
            case 'medium':
                return 'icon-button-medium';

            case 'large':
                return 'icon-button-large';

            default:
                break;
        }
    };
    return (
        <button className={`${className} icon-button  ${getSizeClass()}`} onClick={onClick}>
            <img src={iconSrc} alt='icon-button'/>
        </button>
    );
};

export default IconButton;

import { FC, useCallback, useEffect, useState } from "react";
import "./Feedback.scss";
import { close_btn, close_btn_black, feedback_icon, rate_review, star, star_outline } from "assets/images";
import { RootState, useAppSelector } from "common/redux/core/root.reducer";
import { ApiStatus } from "common/enums";
import TextArea from "common/components/custom/Textarea/TextArea";
import { hooks } from "botframework-webchat-component";
import { IChatRatingEvent } from "common/models/chat/chat-feedback-event.model";

type FeedbackProps = {
};
const Feedback: FC<FeedbackProps> = ({
}: FeedbackProps) => {
  
  const MAX_CHARACTERS_ALLOWED = 500;
  const chatBotState = useAppSelector((state: RootState) => state.chat.botConnectingStatus);
  const chatHistoryPanelState = useAppSelector((state: RootState) => state.chat.showChatHistoryPanel);
  const searchResultState = useAppSelector((state: RootState) => state.chat.searchMessages?.result);
  const [feedbackRating, setFeedbackRating] = useState<number>(0);
  const [feedbackText,setFeedbackText] = useState<string>("");
  const [showFeedbackModal,setShowFeedbackModal] = useState<boolean>(false);
  const [disabled,setDisabled] = useState<boolean>(true);
  const [isMaxCharactersReached,setMaxCharactersReached] = useState<boolean>(false);
  const {
    useSendEvent,
  } = hooks;
  const sendEvent = useSendEvent();

  const ratingGiven = (rating:number) => {
    if(rating == feedbackRating){
        setFeedbackRating(0);
        setDisabled(true);
    }
    else{
    setFeedbackRating(rating);
    !isMaxCharactersReached && setDisabled(false);
    }
  }

  const sendUserRating = (rating,comment) =>{
    const ratingEvent:IChatRatingEvent={
      rating: rating,
      ratingcomment:comment?comment:null
    };
    sendEventToBot("rating",ratingEvent);
    setFeedbackRating(0);
    setFeedbackText("");
    setDisabled(true);
    setShowFeedbackModal(false);
    setMaxCharactersReached(false);
  }
  const sendEventToBot = useCallback(
    (name: any, value: any, event?: any) => {
      event?.preventDefault();
      sendEvent(name, value);
    },
    [sendEvent]
  );

  const handleFeedbackClose = () =>{
    setDisabled(true);
    setShowFeedbackModal(false);
    setMaxCharactersReached(false);
    setFeedbackRating(0);
  }

  const handleTextAreaChange = (value) =>{
    setFeedbackText(value);
    if(value.length > MAX_CHARACTERS_ALLOWED){
      setDisabled(true);
      setMaxCharactersReached(true);
    }
    else{
      feedbackRating && setDisabled(false);
      setMaxCharactersReached(false);
    }
  }


  return chatBotState != ApiStatus.LOADING && !chatHistoryPanelState && !searchResultState ?(
    <>
    {showFeedbackModal && (
    <div className="feedback-modal-wrapper">
       <div className="feedback-modal-container">
           <div className="feedback-modal-container__header">
             <span>We’d love to hear your feedback!</span>
              <img className="close_btn" src={close_btn_black} onClick={handleFeedbackClose}/>
           </div>
          <div className="feedback-modal-container__content">
            <span>How would you rate your overall satisfaction with the chatbot's performance?</span>
            <div className="rating">
            {[...Array(5)].map((item, index) => {
                return (
                    <svg onClick={(e) => ratingGiven(index+1)} className={index+1 <  feedbackRating || feedbackRating === index+1 ?"star" : "non-starred"} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 17.77L18.18 21.5L16.54 14.47L22 9.74L14.81 9.13L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77Z" fill="none"/>
                   </svg>
                )
            })}
            </div>
            <div className="feedback-text">
              <TextArea 
                name={"feedback-text"}
                label="Any additional thoughts?"
                disabled={false}
                onChange={handleTextAreaChange}
                />
                {isMaxCharactersReached && <div className="error">{`Maximum ${MAX_CHARACTERS_ALLOWED} characters allowed`}</div>}
            </div>
          </div>
          <div className="feedback-modal-container__footer">
            <button disabled={disabled} onClick={(e) => sendUserRating(feedbackRating,feedbackText)} className="primary">Submit</button>
            <button onClick={handleFeedbackClose}>Maybe Later</button>  
         </div>
        </div>
    </div>
    )}
    <div className="feedback-wrapper">
        {/* <img src={feedback_icon} onClick={(e) => setShowFeedbackModal(true)}/> */}
        <button onClick={(e) => setShowFeedbackModal(true)}><img src={rate_review}/><span>Give Feedback</span></button>
    </div>
    </>
  ):<></>
};

export default Feedback;

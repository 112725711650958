import { ApiResult } from "common/models";
import { put, takeLatest } from "redux-saga/effects";
import { ChatHistoryService } from "./chatHistory.service";
import { chatHistoryActions } from "./chatHistory.slice";

const getChatHistory = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatHistoryService.getChatHistory(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatHistoryActions.getChatHistoryFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatHistoryActions.getChatHistorySuccess.type,
            payload: value
        });
    }
};

const getChatHistoryWatch = function* () {
    yield takeLatest(chatHistoryActions.getChatHistory.type,getChatHistory);
};

const getSearchResults = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatHistoryService.getSearchResults(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatHistoryActions.getSearchResultsFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatHistoryActions.getSearchResultsSuccess.type,
            payload: value
        });
    }
};

const getSearchResultsWatch = function* () {
    yield takeLatest(chatHistoryActions.getSearchResults.type,getSearchResults);
};

const getChatHistoryData = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatHistoryService.getChatHistoryData(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatHistoryActions.getChatHistoryDataFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatHistoryActions.getChatHistoryDataSuccess.type,
            payload: value
        });
    }
};

const getChatHistoryDataWatch = function* () {
    yield takeLatest(chatHistoryActions.getChatHistoryData.type,getChatHistoryData);
};

const getSelectedChatHistoryDetails = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatHistoryService.getChatHistoryDetails(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatHistoryActions.getSelectedChatHistoryDetailsFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatHistoryActions.getSelectedChatHistoryDetailsSuccess.type,
            payload: value
        });
    }
};

const getChatHistoryDetailsWatch = function* () {
    yield takeLatest(chatHistoryActions.getSelectedChatHistoryDetails.type,getSelectedChatHistoryDetails);
};

const deleteSelectedChatHistoryDetails = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatHistoryService.deleteChatHistoryDetail(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatHistoryActions.deleteSelectedChatHistoryDetailsFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatHistoryActions.deleteSelectedChatHistoryDetailsSuccess.type,
            payload: value
        });
    }
};

const deleteSelectedChatHistoryDetailsWatch = function* () {
    yield takeLatest(chatHistoryActions.deleteSelectedChatHistoryDetails.type,deleteSelectedChatHistoryDetails);
};

const bookmarkChat = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatHistoryService.bookmarkChat(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatHistoryActions.bookmarkChatFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatHistoryActions.bookmarkChatSuccess.type,
            payload: value
        });
    }
};

const bookmarkChatWatch = function* () {
    yield takeLatest(chatHistoryActions.bookmarkChat.type,bookmarkChat);
};

const removeBookmark = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatHistoryService.removeBookmark(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatHistoryActions.removeBookmarkFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatHistoryActions.removeBookmarkSuccess.type,
            payload: value
        });
    }
};

const removeBookmarkWatch = function* () {
    yield takeLatest(chatHistoryActions.removeBookmark.type,removeBookmark);
};

export {
    getChatHistoryWatch,
    getSearchResultsWatch,
    getChatHistoryDataWatch,
    getChatHistoryDetailsWatch,
    deleteSelectedChatHistoryDetailsWatch,
    bookmarkChatWatch,
    removeBookmarkWatch
};

import { ApiResult } from "common/models";
import { HttpClient } from "common/services/http-client.service";
import chatHistoryApiConfig from "./chatHistory.config";
import { UserChatHistoryDataRequest } from "common/models/chat/chat-history.model";
import { ChatHistoryBookmarkRequest } from "common/models/chat/chat-bookmark.model";

export class ChatHistoryService extends HttpClient {
    public static async getChatHistory(searchText:string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatHistoryApiConfig.getChatHistory(searchText));
    }
    public static async getSearchResults(searchText:string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatHistoryApiConfig.getChatHistory(searchText));
    }
    public static async getChatHistoryData(data:UserChatHistoryDataRequest): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatHistoryApiConfig.getChatHistoryData(data));
    }    
    public static async getChatHistoryDetails(conversationGuid:string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatHistoryApiConfig.getChatHistoryDetails(conversationGuid));
    }
    public static async deleteChatHistoryDetail(conversationGuids:Array<string>): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatHistoryApiConfig.deleteChatHistoryDetail(conversationGuids));
    }
    public static async bookmarkChat(bookmarkRequest:ChatHistoryBookmarkRequest): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatHistoryApiConfig.bookmarkChat(bookmarkRequest));
    }
    public static async removeBookmark(conversationGuid:string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatHistoryApiConfig.removeBookmarkChat(conversationGuid));
    }
    
    
}

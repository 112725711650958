import {
  bookmark_border,
  bookmarked,
  chatbot_panel_avatar,
  chatbot_panel_delete,
} from "assets/images";
import { DateRangePicker } from "common/components";
import DotLoader from "common/components/base/DotLoader/DotLoader";
import { DateRange } from "common/models";
import {
  CloseUserConversation,
  UserChatHistoryDataRequest,
} from "common/models/chat/chat-history.model";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "common/redux/core/root.reducer";
import { chatHistoryActions } from "modules/gpo/redux/chatHistory";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./ChatHistoryPanel.scss";
import { chatActions } from "modules/gpo/redux/chat";
import { ChatHistoryBookmarkRequest } from "common/models/chat/chat-bookmark.model";
import { ApiStatus } from "common/enums";
import DeleteConversationModal from "../DeleteConversationModal/DeleteConversationModal";

const ChatHistoryPanel = () => {
  const chatHistoryState = useAppSelector(
    (state: RootState) => state.chatHistory
  );
  const chatState = useAppSelector((state: RootState) => state.chat);
  const dispatch = useAppDispatch();
  const [chatHistoryMessages, setChatHistoryMessages] = useState<
    CloseUserConversation[]
  >([]);
  const [groupedChatHistory, setGroupedChatHistory] = useState<any>({});
  const [activeTab, setActiveTab] = useState("tab1");
  const [dateFilter, setDateFilter] = useState<DateRange>([null, null]);
  const [showDotloader, setShowDotLoader] = useState<boolean>(false);
  const [deleteConversationGuid, setDeleteConversationGuid] = useState<string>('');

  useEffect(() => {
    setShowDotLoader(true);
    
    if (chatState.showChatHistoryPanel) {
      const request: UserChatHistoryDataRequest = {
        toDate: moment(new Date())?.endOf('day')?.toISOString(),
        fromDate: moment(new Date())
          .subtract(30, "days")
          ?.startOf('day')
          ?.toISOString(),
      };
      dispatch(chatHistoryActions?.getChatHistoryData(request));
    }
  }, [chatState.showChatHistoryPanel]);

  useEffect(() => {
    if (chatState.showChatHistoryPanel) {
      const wrapper = document.getElementsByClassName(
        "gpo-custom-chat-container"
      )[0];
      if (wrapper) {
        wrapper?.classList?.add("chat-history-list");
      }
      if (
        chatHistoryState?.chatHistoryData 
      ) {
        setShowDotLoader(false);
        if(chatHistoryState?.chatHistoryData?.isSuccess){
        if(dateFilter)
        {
          setDateFilter([null, null])
        }
        
         updateChatHistory(chatHistoryState?.chatHistoryData?.closedConversations);
        
        }//dataForChat;
      }
    }
  }, [chatState.showChatHistoryPanel, chatHistoryState?.chatHistoryData]);

  useEffect(() => {
    setShowDotLoader(false);
    if (
      (chatHistoryState?.chatHistoryConversationDeleted &&
      chatHistoryState?.chatHistoryConversationDeleted?.isSuccess
      )
      ||
      (
        chatHistoryState?.chatBookmark && 
        chatHistoryState?.chatBookmark?.code==200
      )
      ||
      (
        chatHistoryState?.removeBookmark && 
        chatHistoryState?.removeBookmark?.code==204
      )
    ) {
      const request: UserChatHistoryDataRequest = {
        toDate: moment(new Date())?.endOf('day')?.toISOString(),
        fromDate: moment(new Date())
          .subtract(30, "days")
          ?.startOf('day')
          ?.toISOString(),
      };
      setShowDotLoader(true);
      dispatch(chatHistoryActions?.getChatHistoryData(request));
    }
  }, [chatHistoryState?.chatHistoryConversationDeleted,chatHistoryState?.chatBookmark,chatHistoryState?.removeBookmark]);
  
  useEffect(()=>{
    setShowDotLoader(false);
  },[chatHistoryState?.selectedConversation])
  
  const scrollToBottom = () => {
    const messagesWrapper = document.getElementsByClassName(
      "gpo-custom-chat-messages"
    )[0];
    if (messagesWrapper) {
      messagesWrapper.scroll({
        top: messagesWrapper.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const updateChatHistory = (conversations: CloseUserConversation[]) => {
    const groupedArray: any = conversations?.length
      ? conversations?.reduce(function (obj, item) {
          obj[checkDay(item.createdDate)] =
            obj[checkDay(item.createdDate)] || [];
          obj[checkDay(item.createdDate)].push(item);
          return obj;
        }, {})
      : {};
    setChatHistoryMessages(conversations);
    setGroupedChatHistory(groupedArray);
  };

  const checkDay = (date): string => {
    if (
      moment.utc(date)?.local().isSame(moment(), "day")
    ) {
      return "Today";
    }
    else if (
      moment.utc(date)?.local().isSame(moment().subtract(1, "days"), "days")
    ) {
      return "Yesterday";
    } else if (
      moment(date)?.isSameOrAfter(moment().subtract(7, "days")?.utc(), "days")
    ) {
      return "Past week";
    } else if (
      moment(date)?.isSameOrAfter(moment().subtract(30, "days")?.utc(), "days")
    ) {
      return "Past 30 days";
    } else {
      return "";
    }
  };

  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleDateRangeChange = (date) => {
    if (date && date[0] && date[1]) {
      setDateFilter(date);
      updateChatHistory(filterByDateRange(date));
    } else {
      setDateFilter([null, null]);
      if(chatHistoryState?.chatHistoryData?.closedConversations){
      updateChatHistory(chatHistoryState?.chatHistoryData?.closedConversations);
      }
    }
  };

  const filterByDateRange = (date: DateRange) => {
    const conversations: CloseUserConversation[] = chatHistoryState?.chatHistoryData?.closedConversations ? chatHistoryState?.chatHistoryData?.closedConversations :[];
    console.log("start and end",moment(date[0]).startOf("day")?.toISOString(), moment(date[1]).endOf("day")?.toISOString());
    return conversations.filter((message) =>
      moment?.utc(message.createdDate)
        .isBetween(moment(date[0]).startOf("day")?.toISOString(), moment(date[1]).endOf("day")?.toISOString())
    );
  };

  const deleteConversation = (event: any,guid: string) => {
    event?.preventDefault();
    event?.stopPropagation();
    setDeleteConversationGuid(guid);
  };

  const clearHistory = () =>{
    const conversationGuids =  chatHistoryMessages?.map((chat:CloseUserConversation)=>chat?.coversationGuid)
    dispatch(chatHistoryActions?.deleteSelectedChatHistoryDetails(conversationGuids));
    setShowDotLoader(true);
  }

  const showChatHistory = (coversationGuid: string) =>{
    setShowDotLoader(true);
    dispatch(chatHistoryActions?.getSelectedChatHistoryDetails(coversationGuid));
    dispatch(chatActions?.updateDocOpenState(false));

  }

  const bookmarkConversation = (event:any,guid: string) => {
    event?.preventDefault();
    event?.stopPropagation();
    const request : ChatHistoryBookmarkRequest ={
      ConversationGuid: guid
    }
    dispatch(chatHistoryActions?.bookmarkChat(request));
    //dispatch(chatActions?.updateStopTyping(true));
    setShowDotLoader(true);
  };

  const removebookmarkConversation = (event:any,guid: string) => {
    event?.preventDefault();
    event?.stopPropagation();
    dispatch(chatHistoryActions?.removeBookmark(guid));
    //dispatch(chatActions?.updateStopTyping(true));
    setShowDotLoader(true);
  };

  //sort by date
  const test = () => {
    return chatHistoryMessages?.sort((a, b) => {
      return moment(a.createdDate).diff(moment(b.createdDate));
    });
  };

  const getThirtyDaysFromNow = () =>
    moment(new Date()).subtract(30, "days").toDate();

  return chatState.showChatHistoryPanel ? (
    <div className="chat-history-panel">
      {showDotloader && <DotLoader fullScreen/>}
      <div className="chat-history-panel__container">
        <header className="panel-header">
          {/* <span>ChatHistory Panel</span>
          <img src={close_grey} className="close" 
           onClick={(event:any) => {clearSearch()}}
           /> */}
           <span className="panel-header--bold">Filter by date range</span>        
            <>
              <DateRangePicker
                className="panel-header__date-picker"
                value={dateFilter}
                format="dd/MM/y"
                minDate={getThirtyDaysFromNow()}
                maxDate={new Date()}
                onChange={handleDateRangeChange}
              />
            {chatHistoryMessages?.length!=0 &&
              <div className="panel-header__clear-history">
                <button  onClick={(event) => clearHistory()}>
                  <span>X</span> Clear History
                </button>
              </div>
              }
            </>          
        </header>
        {!!chatHistoryMessages && chatHistoryMessages?.length != 0 && (
          <>
            <div className="tabs">
              <span
                className={activeTab === "tab1" ? "active" : ""}
                onClick={(event) => handleTab("tab1")}
              >
                All ({chatHistoryMessages?.length})
              </span>
              <span
                className={activeTab === "tab2" ? "active" : ""}
                onClick={(event) => handleTab("tab2")}
              >
                Bookmarked (
                {
                  chatHistoryMessages?.filter(
                    (message) => message?.isBookmarked
                  ).length
                }
                )
              </span>
            </div>
            <div className="list-wrapper">
              {Object.keys(groupedChatHistory)?.map(
                (key: keyof typeof groupedChatHistory, index) => (
                  <>
                    <React.Fragment key={index}>
                      {key != "" &&
                        !!groupedChatHistory[key]?.filter(({ isBookmarked }) =>
                          activeTab == "tab2" ? isBookmarked : true
                        )?.length && (
                          <div className="list-wrapper__group">
                            <span className="group-name">
                              {key?.toString()}
                            </span>
                            {key != "" &&
                              groupedChatHistory[key] &&
                              groupedChatHistory[key]
                                ?.filter(({ isBookmarked }) =>
                                  activeTab == "tab2" ? isBookmarked : true
                                )
                                ?.map((message: CloseUserConversation, i) => (
                                  <React.Fragment
                                    key={message?.coversationGuid}
                                  >
                                    <div className="list-wrapper-item" onClick={() =>
                                            showChatHistory(
                                              message?.coversationGuid
                                            )
                                          }>
                                      <img
                                        className="item-bot"
                                        src={chatbot_panel_avatar}
                                      />
                                      <p className="item-text">
                                        {message?.caption}
                                      </p>
                                      {message?.isBookmarked ? (
                                        <img
                                          className="item-bookmark"
                                          src={bookmarked}
                                          onClick={(event) =>
                                            removebookmarkConversation(
                                              event,
                                              message?.coversationGuid
                                            )
                                          }
                                        />
                                      ) : (
                                        <img
                                          className="item-bookmark"
                                          src={bookmark_border}
                                          onClick={(event) =>
                                            bookmarkConversation(
                                              event,
                                              message?.coversationGuid
                                            )
                                          }
                                        />
                                      )}

                                      <img
                                        className="item-delete"
                                        src={chatbot_panel_delete}
                                        onClick={(event) =>
                                          deleteConversation(
                                            event,
                                            message?.coversationGuid
                                          )
                                        }
                                      />
                                    </div>
                                  </React.Fragment>
                                ))}
                          </div>
                        )}
                    </React.Fragment>
                  </>
                )
              )}
            </div>
          </>
        )}
        {chatHistoryMessages && !chatHistoryMessages?.length && !showDotloader && (
          <div className="no-data">
            <p>No history available</p>
          </div>
        )}
      </div>
      {deleteConversationGuid && (
        <DeleteConversationModal
          onClose={() => setDeleteConversationGuid("")}
          onDelete={() => {
            dispatch(
              chatHistoryActions?.deleteSelectedChatHistoryDetails([deleteConversationGuid])
            );
            setDeleteConversationGuid("");
            setShowDotLoader(true);
          }}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default ChatHistoryPanel;
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "common/components";

type DeleteConversationModalProps = {
  onClose: () => void;
  onDelete: () => void;
};

const DeleteConversationModal = ({
  onClose,
  onDelete,
}: DeleteConversationModalProps) => {
  return (
    <Modal>
      <ModalHeader onClose={onClose}>Delete Conversation</ModalHeader>
      <ModalBody className="document-list-modal">
        <p>Do you want to delete the selected conversation?</p>
      </ModalBody>
      <ModalFooter>
        <Button variant="primary-alt" onClick={onDelete}>
          Delete
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConversationModal;

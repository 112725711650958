import { useEffect, useRef, useState } from "react";
import { close_grey, search } from "assets/images";
import useDebounce from "common/hooks/useDebounce";
import { chatHistoryActions } from "modules/gpo/redux/chatHistory";
import { ChatHistoryModel, UserChatHistory } from "common/models/chat/chat-history.model";
import { chatActions } from "modules/gpo/redux/chat";
import "./Search.scss";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { clear } from "@testing-library/user-event/dist/clear";
import moment from "moment";
import { ApiStatus } from "common/enums";

const DEBOUNCE_TIME_OUT = 1000;

const Search = () => {

  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_TIME_OUT);
  const chatHistoryState = useAppSelector((state: RootState) => state.chatHistory);
  const chatState = useAppSelector((state: RootState) => state.chat);
  const chatHistoryDispatch = useAppDispatch(); 

  useEffect(() => {
    if (debouncedSearchTerm.length > 0) {
      searchInChat(debouncedSearchTerm);
    }
    else{
      clearSearch();
    }
  }, [debouncedSearchTerm]);

  useEffect(()=>{
    if(chatState?.searchMessages.status == ApiStatus.NONE){
      setSearchTerm('');
      }
  },[chatState?.searchMessages.status])

  const handleSearchOnChange = (event: any) => {
    if (event && event.currentTarget && event.currentTarget.value) {
      setSearchTerm(event.currentTarget.value);
    } else {
      setSearchTerm('');
    }
  };
  const searchInChat = (searchValue:string) => {
    if(searchValue==""){
      clearSearch();
    }

    const startDate = moment(new Date())
    .subtract(30, "days")
    ?.utc()
    .toDate()
    ?.toISOString();

    chatHistoryDispatch(chatActions.searchChatBegin({
      searchText:searchValue,
      startDate
    })); 
    chatHistoryDispatch(chatActions?.updateShowChatHistoryPanel(false));
  };
  const scrollToBottom = () =>{
    const messagesWrapper = document.getElementsByClassName('gpo-custom-chat-messages')[0];
    if(messagesWrapper){
      messagesWrapper.scroll({ top: messagesWrapper.scrollHeight,behavior:'smooth'});
    }
  }

  const clearSearch = () =>{
    const searchElem:any = document.getElementById('history-search');
    if(searchElem){
      searchElem.value = "";
      setSearchTerm('');
    }
    
    const chatHistoryElement = document.querySelector(".gpo-chat-history");
    const selectedSearchChat = document?.querySelector(".selectedSearch");
    if(selectedSearchChat){
      selectedSearchChat?.classList?.remove("selectedSearch");
    }
    chatHistoryDispatch(chatActions?.resetSearchChat());   
    const wrapper = document.getElementsByClassName("gpo-custom-chat-container")[0];
    if(wrapper){
      wrapper?.classList?.remove("message-list");
    }
    scrollToBottom();
  }

  const handleClearBtnClick = () =>{
    clearSearch();
    chatHistoryDispatch(chatActions.resetSearchChat());
    chatHistoryDispatch(chatHistoryActions.clearSelectedConversation());

  }

  return (
    <div className="search">
      <div className="search__container">
        <section>
          <img src={search} />
          <input id="history-search" type="text" placeholder="Search messages..." disabled={chatState.botConnectingStatus == ApiStatus.LOADING}
            onChange={handleSearchOnChange}
          />
          <img src={close_grey} className="close" onClick={handleClearBtnClick} />
        </section>
      </div>
    </div>
  );
};

export default Search;
import React from "react";
import { useNavigate } from "react-router-dom";

import { bot } from "assets/images";

import "./Welcome.scss";

const Welcome = () => {
  const navigate = useNavigate();
  return (
    <div className="welcome-wrapper">
      <div className="welcome-wrapper__container">
        <header>
          ChatBot by <span>GPO</span>
        </header>
        <section>
          <div className="notes">
            <h1>Our AI will help you with your payroll queries</h1>
            <p>
            It’s a GPT-3-powered intelligent chatbot to answer payroll questions
            </p>
            <button type="button" onClick={() => navigate("chat")}>
              START CHAT
            </button>
          </div>
          <img src={bot} />
        </section>
      </div>
    </div>
  );
};

export default Welcome;
